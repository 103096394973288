import { useGetUpperProcessById } from '@hooks/useBackendApi';
import { ProcessViewContext } from '@pages/process/ProcessView';
import { ProcessInputs } from '@typeList/types';
import Tree, { BasicDataNode } from 'rc-tree';
import 'rc-tree/assets/index.css';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import iconAdd from '@images/text_tree/icon_add.svg';
import iconClose from '@images/text_tree/icon_close.svg';

import ErrorMsg from '@common/error/ErrorMsg';
import { css } from '@emotion/react';
import iconFile from '@images/text_tree/icon_file.svg';
import iconRemainder from '@images/text_tree/icon_remainder.svg';
import iconRoop from '@images/text_tree/icon_roop.svg';
import { formatNumber } from '@utils/utils';
import { EventDataNode } from 'rc-tree/lib/interface';
import { useTranslation } from 'react-i18next';
import { FadeLoader } from 'react-spinners';


interface TreeNode extends BasicDataNode {
    key: string;
    title: React.ReactNode;
    children?: TreeNode[];
    icon?: React.ReactNode;
    id?: number;
    name?: string;
    process_output_id?: number;
    process_output_amount?: number;
}


/**
 * テキストツリー情報
 * @returns
 */
const TextTreeDisplay = () => {
    const { t } = useTranslation();
    const context = useContext(ProcessViewContext);
    const { userData, processData, processIos, treeData, setTreeData } = context


    // 上流プロセス取得API
    const {
        getUpperProcess,
        upperProcessData,
        upperProcessLoading,
        upperProcessError
    } = useGetUpperProcessById();

    // 展開中のツリー
    const [expandedProcessKeys, setExpandedProcessKeys] = useState<any[]>([])
    const [expandedProcessIds, setExpandedProcessIds] = useState<any[]>([]);
    const handleExpand = (expandedKeys: React.Key[], info: { node: EventDataNode<TreeNode>; expanded: boolean; nativeEvent: MouseEvent }) => {
        setExpandedProcessKeys(expandedKeys.map(String));
        // setExpandedProcessIds([...expandedProcessIds, info.node.id])
    };

    // debug
    // useEffect(() =>{
    //     console.log("treeData", treeData)
    // },[treeData])

    // 初期表示
    useEffect(() => {
        const fetchInitialTreeData = async () => {
            if (processData && treeData.length === 0) {
                const responce = await getUpperProcess(processData.id)

                // すべてのIDを収集
                const newExpandedIds: number[] = [];

                // 初期データ
                const initialData: TreeNode[] = await Promise.all(
                    responce.process_inputs
                        .filter((process: ProcessInputs) => process.upper_output)
                        .map(async (process_input, index) => {
                            const processId = process_input.upper_output?.process.id;
                            const amount = process_input.amount ?? 0;
                            const processOutputId = process_input.upper_output?.id ?? undefined;
                            const processOutputAmount = process_input.upper_output?.amount ?? amount;
                            if (processId !== undefined) {
                                newExpandedIds.push(processId);
                            }
                            const title = process_input.upper_output?.process.name
                                ? `${process_input.upper_output?.process.name}
                                    (${userData
                                    ? formatNumber(amount, userData?.significant_figures, userData?.use_decimal_notation, userData?.decimal_places)
                                    : amount}
                                    ${process_input.upper_output?.unit?.name || ''})`
                                : '';
                            return {
                                key: `Index-${index}_Upper_Process_${processId}_Time-${Date.now()}`,
                                id: processId,
                                // title: process_input.upper_output?.process.name,
                                title: title,
                                children: [], // 取得したデータをchildrenに設定
                                isLeaf: false,
                                process_output_id: processOutputId,
                                process_output_amount: processOutputAmount,
                            };
                        })
                );
                setExpandedProcessIds((prev) => [...prev, ...newExpandedIds]);
                setTreeData(updateTreeData(initialData, "", [], false, [])); // 初回呼び出しで ancestors を空配列で渡す
            }
        };

        fetchInitialTreeData();
    }, [processData]);


    // ロード中のノードを管理
    const [loadingKeys, setLoadingKeys] = useState<string[]>([]);

    const onLoadData = async (treeNode: TreeNode) => {
        // すでにロード済みの子ノードがある場合、処理をスキップ
        if (treeNode.children && treeNode.children.length > 0) {
            return;
        }


        // 一時的にローディングインジケータを表示するためにノードを更新
        setTreeData((prevData) =>
            updateTreeData(prevData, treeNode.key, [
                {
                    key: `Process_${treeNode.id}_Loading_Time-${Date.now()}`,
                    title:
                        <FadeLoader
                            color="#48bdbb"
                            height={5}
                            radius={1}
                            width={3}
                            margin={-5}
                            cssOverride={css`
                            top: 18px
                        `}
                        />,
                    isLeaf: false
                }
            ])
        );

        // 上流プロセスデータを取得
        try {
            // const upperProcess = await getUpperProcess(treeNode.id || 0);
            const upperProcess = await getUpperProcess(treeNode.id || 0, treeNode.process_output_id || 0, treeNode.process_output_amount || 0);

            // すべてのIDを収集
            const newExpandedIds: number[] = [];

            // 製プロセスのデータをツリー構造に変換
            const children: TreeNode[] = upperProcess.process_inputs
                .filter((process: ProcessInputs) => process.upper_output) // upper_outputがnull/undefinedのものを除外
                .map((process: ProcessInputs, index: number) => {
                    const processId = process.upper_output?.process.id;
                    const amount = process.amount ?? 0;
                    const processOutputId = process.upper_output?.id ?? undefined;
                    const processOutputAmount = process.upper_output?.amount ?? amount;
                    if (processId !== undefined) {
                        newExpandedIds.push(processId);
                    }
                    const title = process.upper_output?.process.name
                        ? `${process.upper_output?.process.name}
                            (${userData
                            ? formatNumber(processOutputAmount, userData?.significant_figures, userData?.use_decimal_notation, userData?.decimal_places)
                            : processOutputAmount}
                            ${process.upper_output?.unit?.name || ''})`
                        : '';
                    return {
                        key: `Index-${index}_ProcessId-${processId}_Time-${Date.now()}`,
                        id: processId,
                        title: (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {/* {process.upper_output?.process.name} */}
                                {title}
                            </div>
                        ),
                        children: [], // 子ノードは空の配列として初期化
                        isLeaf: false,// このノードはリーフではない
                        process_output_id: processOutputId,
                        process_output_amount: processOutputAmount,
                    }
                });

            // 子ノードがない場合、isLeafをtrueに設定
            const isLeaf = children.length === 0;

            // //　展開済みプロセスを管理
            setExpandedProcessIds((prev) => [...prev, ...newExpandedIds]);

            // ツリーに取得したデータを更新
            setTreeData((prevData) =>
                updateTreeData(prevData, treeNode.key, children, isLeaf)
            );
        } finally {
            setLoadingKeys((prev) => prev.filter((key) => key !== treeNode.key)); // ローディング終了
        }
    };

    // ツリーデータの更新関数
    const updateTreeData = (
        list: TreeNode[],
        key: string,
        children: TreeNode[],
        isLeaf?: boolean,
        ancestors: number[] = [] // 先祖の ID を保持する引数を追加
    ): TreeNode[] => {
        return list.map((node, index) => {
            // 現在のノードの祖先リストを更新
            const updatedAncestors = node.id !== undefined ? [...ancestors, node.id] : ancestors;
            if (node.key === key) {

                // アイコンを動的に決定
                const icons: React.ReactNode[] = [];

                const occurrenceCount = expandedProcessIds.filter((id) => id === node.id).length;
                const shouldShowIconFile = occurrenceCount >= 2;
                if (shouldShowIconFile) {
                    // ツリー内既出
                    icons.push(<IconFile key={`Index-${index}_Icon-File_${node.id}_Time-${Date.now()}`} />);
                }


                const isInAncestors = node.id !== undefined && ancestors.includes(node.id);
                if (isLeaf) {
                    // リマインダーフロー
                    icons.push(<IconRemainder key={`Index-${index}_Icon-Remainder_${node.id}_Time-${Date.now()}`} />);
                } else if (isInAncestors) {
                    // エンドレスループチェック（祖先ノードに同じIDが存在する場合）
                    icons.push(<IconRoop key={`Index-${index}_Icon-Roop_${node.id}_Time-${Date.now()}`} />);
                }

                const icon = (
                    <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                        {icons}
                    </div>
                );

                return {
                    ...node,
                    children: children.map((child) =>
                        // 子ノードに祖先のリストを渡す
                        updateTreeData([child], child.key, child.children || [], child.isLeaf, updatedAncestors)[0]
                    ),
                    isLeaf: isLeaf ?? node.isLeaf,
                    icon
                };
            }
            if (node.children) {
                return {
                    ...node,
                    children: updateTreeData(node.children, key, children, isLeaf, updatedAncestors)
                };
            }

            return node;
        });
    };

    const renderCustomTitle = (node: TreeNode) => {
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <span>{node.title}</span>
                {node.icon && <span style={{ marginLeft: "8px" }}>{node.icon}</span>}
            </div>
        );
    };


    // プロセスの選択
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [selectedId, setSelectedID] = useState<number>();
    const [selectedKeysError, setSelectedKeysError] = useState<any>(null);
    const onSelect = (selectedKeys: any, info: any) => {
        setSelectedID(info.node.id);
        setSelectedKeys(selectedKeys);
    };


    // 選択したプロセスの表示
    const handleClickSelectProcess = async () => {
        if (selectedKeys.length > 0 && selectedId) {
            const process_id = selectedId;
            window.open(`/process/process_view/${process_id}`, '_blank');
        }
    };


    return (
        <Section>
            <Wrap>
                <Grid>
                    <TreeMain>
                        {upperProcessError && <ErrorMsg> {upperProcessError}</ErrorMsg>}
                        <Tree
                            treeData={treeData}
                            loadData={onLoadData}
                            loadedKeys={loadingKeys}
                            onExpand={handleExpand}
                            showLine={true}
                            showIcon={false} // デフォルトのアイコンを無効化
                            titleRender={renderCustomTitle} // カスタムタイトルを指定
                            selectedKeys={selectedKeys}
                            onSelect={onSelect}
                            className="custom-tree-prosess"
                        />
                    </TreeMain>
                    <SelectButton
                        type='button'
                        onClick={handleClickSelectProcess}
                        disabled={selectedKeys.length === 0}
                    >
                        {t('選択プロセスを表示')}
                    </SelectButton>
                    <TreeSide>
                        <Ul>
                            <LiRoop> {t('エンドレスループ検出')}</LiRoop>
                            <LiRemainder> {t('リマインダーフロー')}</LiRemainder>
                            <LiFile> {t('ツリー内既出')}</LiFile>
                        </Ul>
                    </TreeSide>

                </Grid>
            </Wrap>
        </Section>
    );
};

export default TextTreeDisplay;

const Ul = styled.ul`
    list-style: none;
`;

const LiRoop = styled.li`
    margin-bottom: 12px;
    font-weight: 500;
    line-height: 20px;
    position: relative;
    padding-left: 24px;

    &:before {
    iconRoop
        mask: url(${iconRoop}) no-repeat center center / contain;
        -webkit-mask: url(${iconRoop}) no-repeat center center / contain;
        background: #1AA11F;

        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
    }
`;

const LiRemainder = styled.li`
    margin-bottom: 12px;
    font-weight: 500;
    line-height: 20px;
    position: relative;
    padding-left: 24px;

    &:before {
    iconRoop
        mask: url(${iconRemainder}) no-repeat center center / contain;
        -webkit-mask: url(${iconRemainder}) no-repeat center center / contain;
        background-color: var(--color-orange-01);

        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
    }
`;


const LiFile = styled.li`
    margin-bottom: 12px;
    font-weight: 500;
    line-height: 20px;
    position: relative;
    padding-left: 24px;

    &:before {
        background: url(${iconFile}) no-repeat center center / contain;

        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
    }
`;



const styles = `
    .custom-tree-prosess .rc-tree-switcher_close:before {
        width: 10px;
        height: 10px;
        background-image: url(${iconAdd});
        background-size: 10px;
        content: "";
    }
    .custom-tree-prosess .rc-tree-switcher_open:before {
        width: 10px;
        height: 2px;
        background-image: url(${iconClose});
        background-size: 10px 2px;
        content: "";
    }
    .rc-tree .rc-tree-treenode span.rc-tree-switcher,
    .rc-tree .rc-tree-treenode span.rc-tree-checkbox,
    .rc-tree .rc-tree-treenode span.rc-tree-iconEle {
        background-image: none;
    }

    .custom-tree-prosess  .rc-tree-switcher_open {
        display: grid !important;
        width: 20px !important;
        height: 20px !important;
        margin-right: 8px !important;
        background-image: none !important;
        place-content: center !important;
        border: 2px solid var(--color-line-primary) !important;
        background-color: #fff !important;
        border-radius: 50% !important;

        background-position: -75px -56px;
    }

    .custom-tree-prosess .rc-tree-switcher_close {
        display: grid !important;
        width: 20px !important;
        height: 20px !important;
        margin-right: 8px !important;
        background-image: none !important;
        place-content: center !important;
        border: 2px solid var(--color-line-primary) !important;
        background-color: #fff !important;
        border-radius: 50% !important;
    }

    .custom-tree-prosess .rc-tree-treenode {
        font-weight: 500;
        display: ruby !important;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;

        flex-wrap: nowrap !important; /* 折り返しを防ぐ */
        white-space: nowrap !important;  /* テキストの折り返しを防止 */
    }

    /* ツリー全体のスクロール調整 */
    .custom-tree-prosess {
        overflow-x: auto; /* 横スクロールを許可 */
    }
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);


const Section = styled.section`
`

const Wrap = styled.div`
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: max(63.008%, 700px) 1fr;
    grid-template-rows: 27px 1fr;
    gap: 24px 40px;
`

const TreeMain = styled.div`
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    min-height: 60vh;
    padding: 40px;
    background-color: #fff;
    overflow-y: auto;

    overflow-x: auto; /* 横スクロールを許可 */

    max-height: 600px;
    display: flex;
    flex-direction: column;
`

const SelectButton = styled.button`
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    font-size: 12px;
    font-weight: 500;
    width: 140px;
    height: 27px;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    background-color: var(--color-gray--01);
    gap: 0 4px;
    padding: 8px 24px;
    border-radius: 20px;

    appearance: none;
    border: none;
    font: inherit;
    outline: none;

    &:hover {
        color: #fff;
        background-color: var(--color-site-secondary);
    }
`

const TreeSide = styled.div`
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    align-self: start;
    width: fit-content;
    padding: 24px;
    background-color: #fff;
`

const IconRemainder = styled.span`
    mask: url(${iconRemainder}) no-repeat center center / contain;
    -webkit-mask: url(${iconRemainder}) no-repeat center center / contain;
    background-color: var(--color-orange-01);
    display: block;
    width: 20px;
    height: 20px;
`;

const IconRoop = styled.span`
    mask: url(${iconRoop}) no-repeat center center / contain;
    -webkit-mask: url(${iconRoop}) no-repeat center center / contain;
    background-color: #1AA11F;
    display: block;
    width: 20px;
    height: 20px;
`;

const IconFile = styled.span`
    mask: url(${iconFile}) no-repeat center center / contain;
    -webkit-mask: url(${iconFile}) no-repeat center center / contain;
    background-color: var(--color-gray--01);
    display: block;
    width: 20px;
    height: 20px;
`;