import styled from '@emotion/styled';
import { useGetReferredIo } from '@hooks/useBackendApi';
import { ProcessViewContext } from '@pages/process/ProcessView';
import ProcessAllocationProductSearchModal from '@specific/process/process_allocation/ProcessAllocationProductSearchModal';
import { AllocationOut, AlternativeOut, Cutoff, DisposalOut } from '@typeList/types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SelectedAlternativeInfoProps {
    isModalOpen: boolean;
    exchangeId: number;
    rowIndex: number;
    setIsModalOpen: (isOpen: boolean) => void;
    onProductSelect: (product: { id: number, name: string }, rowIndex: number) => void;
}


/**
 * 配分：代替
 *
 * @param param0
 * @returns
 */
const SelectedAlternativeInfo: React.FC<SelectedAlternativeInfoProps> = ({
    isModalOpen,
    exchangeId,
    rowIndex,
    setIsModalOpen,
    onProductSelect
}) => {
    const { t } = useTranslation()
    const context = useContext(ProcessViewContext);
    const { outProduct, setOutProduct, processData, setChangeCheckAllocation } = context;

    // 代替の型チェック
    const isAlternativeOut = (treatment: AllocationOut | Cutoff | DisposalOut | AlternativeOut | null): treatment is AlternativeOut => {
        return treatment !== null && treatment.type === 'alternative';
    }

    // 選択した製品
    const [selectedProductId, setSelectedProductId] = useState<number | null>(null);
    const [selectedProductName, setSelectedProductName] = useState<string | null>(null);
    const [selectedUpperProcessName, setSelectedUpperProcessName] = useState<string | null>(null);
    useEffect(() => {
        if (!outProduct) {
            return
        }
        const treatmnet = outProduct[rowIndex].treatment
        if (isAlternativeOut(treatmnet) && treatmnet.alternative_process_input) {
            setSelectedProductId(treatmnet.alternative_process_input.upper_process_io_id)
            setSelectedProductName(treatmnet.alternative_process_input.upper_process_io_exchange_name)
            setSelectedUpperProcessName(treatmnet.alternative_process_input.upper_process_name)
        }
    }, [])


    // 製品を参照しているプロセス入出力
    const { getReferredIo, referredIoData } = useGetReferredIo();
    useEffect(() => {
        if (selectedProductId && selectedProductId !== 0) {
            try {
                getReferredIo(selectedProductId);
            } catch (error) {
                console.log(error)
            }
        }
    }, [selectedProductId]);


    // 製品選択
    const handleProductSelect = (product: { id: number, name: string }) => {
        setSelectedProductId(product.id);
        setSelectedProductName(product.name);
        // onProductSelect(product, rowIndex);
        if (outProduct) {
            const updatedOutProduct = [...outProduct];
            if (updatedOutProduct[rowIndex].treatment?.type === 'alternative') {
                const treatment = updatedOutProduct[rowIndex].treatment as any;
                treatment.alternative_process_input = {
                    upper_process_io_id: product.id,
                    upper_process_name: product.name,
                };
                setOutProduct(updatedOutProduct);
                setChangeCheckAllocation(true);
            }
        }
    };

    // 選択した上流のID
    const [selectedReferredIoId, setSelectedReferredIoId] = useState<number | null>();

    //　代替率
    const [alternativeRate, setAlternativeRate] = useState<number>(0);

    // 上流の初期値
    useEffect(() => {

        if (!outProduct) {
            return
        }
        const treatmnet = outProduct[rowIndex].treatment
        if (isAlternativeOut(treatmnet) && treatmnet.alternative_process_input) {
            setSelectedReferredIoId(treatmnet.alternative_process_input.upper_process_io_id)
            setAlternativeRate(treatmnet.alternative_rate * 100)
        }
    }, []);

    // 上流切り替え
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        // 選択したallocationの上流ID
        const selectedId = event.target.value;
        if (!referredIoData || !outProduct) {
            return
        }
        const selectedProcessName = referredIoData.find(option => option.id === parseInt(selectedId))?.process_name;
        setSelectedReferredIoId(Number(selectedId));

        const updatedOutProduct = [...outProduct];
        const treatment = updatedOutProduct[rowIndex].treatment

        // 代替以外からの変更の場合
        if (!isAlternativeOut(treatment)) {
            updatedOutProduct[rowIndex].treatment = {
                type: 'alternative',
                alternative_process_input: {
                    process_input_id: null,
                    upper_process_id: selectedProductId,
                    process_input_no: null,
                    upper_process_global_id: "",
                    upper_process_name: selectedProcessName || "",
                    upper_process_io_id: Number(selectedId),
                    upper_process_io_exchange_name: selectedProductName || ""
                },
                alternative_rate: alternativeRate,
            }
        } else {
            // 代替からの変更の場合
            updatedOutProduct[rowIndex].treatment = {
                type: 'alternative',
                alternative_process_input: {
                    process_input_id: null,
                    upper_process_id: selectedProductId,
                    process_input_no: null,
                    upper_process_global_id: "",
                    upper_process_name: selectedProcessName || "",
                    upper_process_io_id: Number(selectedId),
                    upper_process_io_exchange_name: selectedProductName || ""
                },
                alternative_rate: alternativeRate,
            };
        }
        setOutProduct(updatedOutProduct);
        setChangeCheckAllocation(true);
    };


    // 代替率変更
    const handleRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rate = parseFloat(event.target.value);
        setAlternativeRate(rate);

        if (outProduct && isAlternativeOut(outProduct[rowIndex].treatment)) {
            const updatedOutProduct = [...outProduct];
            const treatment = updatedOutProduct[rowIndex].treatment as AlternativeOut;
            treatment.alternative_rate = rate / 100;
            setOutProduct(updatedOutProduct);
            setChangeCheckAllocation(true);
        }
    };

    return (
        <>
            <Div>
                <DivWrap>
                    <Input
                        type="text"
                        value={selectedProductName || ''}
                        readOnly
                    />
                    <input
                        type="hidden"
                        value={selectedProductId || ''}
                    />
                </DivWrap>
                {/* <button onClick={() => setIsModalOpen(true)}>製品を選択</button> */}
                <ProcessAllocationProductSearchModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onSelectProduct={handleProductSelect}
                />

                <SelectLabel>
                    <Select value={selectedReferredIoId ?? "0"} onChange={handleChange}>
                        {selectedUpperProcessName &&
                            <option value="0">{selectedUpperProcessName}</option>
                        }
                        {!selectedUpperProcessName &&
                            <option value="0">{t('選択してください')}</option>
                        }
                        {referredIoData
                            ?.filter((referredIo) => referredIo.process_output_treatment_type === "allocation")
                            .map((referredIo, index) => (
                                <option key={`${referredIo.id}-${index}`} value={referredIo.id}>
                                    {referredIo.process_name}
                                </option>
                            ))}
                    </Select>
                    <Span>{t('代替率')}</Span>
                    <NumberInput
                        type="number"
                        step="0.01"
                        value={alternativeRate}
                        onChange={handleRateChange}
                    />%
                </SelectLabel>
            </Div>
        </>
    );
};

export default SelectedAlternativeInfo;

const Div = styled.div`
display: flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
gap: 8px;
flex: 1 0 1%;
`

const DivWrap = styled.label`
width: min(100%, 140px);
position: relative;
display: block;
`

const Input = styled.input`
position: relative;
width: 100%;
padding: 12px 32px 12px 12px;
cursor: pointer !important;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
appearance: none;
`

const SelectLabel = styled.label`
width: min(100%, 340px);
position: relative;
display: block;
flex: 1 0 1%;
`
const Select = styled.select`
position: relative;
width: 40%;
padding: 12px 32px 12px 12px;
cursor: pointer !important;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
appearance: none;
`

const NumberInput = styled.input`
position: relative;
width: 30%;
padding: 12px 32px 12px 12px;
cursor: pointer !important;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const Span = styled.span`
font-size: 16px;
padding: 0px 10px;
`
