import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '@common/modal/Modal';
import { useTranslation } from 'react-i18next';
import { ProcessViewContext } from '@pages/process/ProcessView';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import { useGetCaseStudyFactorAnalysis } from '@hooks/useBackendApi';
import { FadeLoader } from 'react-spinners';
import DataTable from '@common/table/DataTable';
import { createColumnHelper } from '@tanstack/react-table';
import { CaseStudyFactorAnalysis } from '@typeList/types';
import ReactApexChart from 'react-apexcharts';


interface LciaFactorAnalysisProps {
    isModalOpen: boolean,
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    grouping_type? : string,
    lcia_model_id: number,
    amount: number,
    groupingName?: string, //  グルーピング名
    groupingKeyId: number | null,
    subGroupingName?: string, //  サブグルーピング名
    subGroupingKeyId: number | null

}

/**
 * LCIA 要因分析
 * @returns 
 */
const LciaFactorAnalysisModal: React.FC<LciaFactorAnalysisProps> = ({ 
    isModalOpen,
    setIsModalOpen,
    grouping_type = "total",
    lcia_model_id,
    amount,
    groupingName,
    groupingKeyId,
    subGroupingName,
    subGroupingKeyId
 }) => {

    const { t } = useTranslation();
    const processViewContext = useContext(ProcessViewContext);
    const caseStudyViewContext = useContext(CaseStudyViewContext);

    const { 
        // サブシステムカテゴリ
        selectCharacterizationCategorySet,
        caseStudyData
    } = caseStudyViewContext;

    const userData = processViewContext.userData || caseStudyViewContext.userData;

    // モーダル操作
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => {setIsModalOpen(false); setChartData(null); setCaseStudyFactorAnalysisData(null);};


    // 要因分析API
    const { getCaseStudyFactorAnalysis, caseStudyFactorAnalysisData, caseStudyFactorAnalysisLoading, caseStudyFactorAnalysisError, setCaseStudyFactorAnalysisData } = useGetCaseStudyFactorAnalysis();
    useEffect(()=>{
        if(isModalOpen && caseStudyData) {
            try {
                if(grouping_type === "total"){
                    getCaseStudyFactorAnalysis(
                        caseStudyData?.id ,
                        grouping_type,
                        null,
                        lcia_model_id,
                        null,
                        null,
                        amount
                    )
                }

                if(grouping_type === "subsystem" || grouping_type === "upper_process" ){
                    getCaseStudyFactorAnalysis(
                        caseStudyData?.id ,
                        grouping_type,
                        null,
                        lcia_model_id,
                        groupingKeyId,
                        subGroupingKeyId,
                        amount
                    )
                }

                if(grouping_type === "subsystem_category"){
                    getCaseStudyFactorAnalysis(
                        caseStudyData?.id ,
                        grouping_type,
                        selectCharacterizationCategorySet,
                        lcia_model_id,
                        groupingKeyId,
                        subGroupingKeyId,
                        amount
                    )
                }

            } catch (error) {
                console.log(error)
            }
        }
    },[isModalOpen])


    // テーブル設定
    const columnHelper = createColumnHelper<any>()
    const columns = [
        columnHelper.accessor('exchange_name', {
            header: () => t('基本フロー名'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('category1_name', {
            header: () => t('カテゴリ1'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('category2_name', {
            header: () => t('カテゴリ2'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('category3_name', {
            header: () => t('カテゴリ3'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('amount', {
            header: () => t('流量'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('factor', {
            header: () => t('LCIA係数'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('result_value', {
            header: () => t('LCIA結果'),
            cell: info => info.renderValue(),
        }),
    ]

    // ApexChart用のデータ作成
    const [chartData, setChartData] = useState<any>(null);
    useEffect(() =>{
        if (caseStudyFactorAnalysisData) {
            // result_value が 0 のデータを除外し、降順にソート
            const sortedData = [...caseStudyFactorAnalysisData.breakdown]
                .filter(item => item.result_value !== 0)
                .sort((a, b) => b.result_value - a.result_value); // 降順ソート

            setChartData({
                series: sortedData.map(item => item.result_value),
                options: {
                    chart: {
                        type: 'pie' as 'pie', // 明示的にキャスト
                        height: 800, // グラフの高さを指定
                        width: 800,  // グラフの幅を指定
                    },
                    labels: sortedData.map(({ exchange_name, category1_name, category2_name, category3_name }) => {
                        const categories = [category1_name, category2_name, category3_name]
                            .filter(Boolean) // 有効な値だけ取得
                            .join("/"); // スラッシュで結合
                        return `${exchange_name} (${categories})`;
                    }),
                    legend: {
                        position: "bottom", // 凡例を下部に配置
                        horizontalAlign: "left", // 水平方向の配置
                        layout: "vertical",
                        floating: false, // レイアウトを固定
                        itemMargin: {
                            vertical: 5, // 項目ごとの余白
                        },
                    }
                }
            });
        }
    }, [caseStudyFactorAnalysisData]);


    return (
        <>
            {userData?.is_admin &&
                <Modal isOpen={isModalOpen} onClose={closeModal}>
                    <Inner>
                        <SectionTitle>
                            <Title>
                                [SystemTest]LCIA 要因分析
                            </Title>
                        </SectionTitle>

                        {caseStudyFactorAnalysisLoading &&
                            <FadeLoader
                                color="#48bdbb"
                                height={10}
                                radius={2}
                                width={5}
                            />
                        }

                        {!caseStudyFactorAnalysisLoading &&
                            <>
                                {caseStudyFactorAnalysisData &&
                                    <Section>
                                        <SectionText>{t('影響評価手法')}: {caseStudyFactorAnalysisData.impact_assessment_method_name}</SectionText>
                                        <SectionText>{t('影響カテゴリ')}: {caseStudyFactorAnalysisData.impact_category_name}</SectionText>
                                        <SectionText>{t('指標')}: {caseStudyFactorAnalysisData.lcia_indicator_name}</SectionText>
                                    </Section>
                                }

                                {chartData &&
                                <ChartContainer>
                                    <ReactApexChart
                                        options={chartData.options}
                                        series={chartData.series}
                                        type="pie"
                                        width={800}
                                    />
                                </ChartContainer>
                                }

                                {caseStudyFactorAnalysisData &&
                                    <DataTable
                                        count={0}
                                        columns={columns}
                                        data={(caseStudyFactorAnalysisData?.breakdown)}
                                        paging_flag={false}
                                    />
                                }
                            </>
                        }

                    </Inner>
                </Modal>
            }
        </>
    );
};

export default LciaFactorAnalysisModal;

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`;

const Title = styled.h3`
    font-size: 18px;
    font-weight: 500;
`

const Section = styled.section`
    margin-bottom: 32px;
    display: block;
`

const SectionTitle = styled.div`
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    display: flex;
    justify-content: space-between;
`

const SectionText = styled.div`
    font-size: 14px;
    font-weight: 500;
`

const ChartContainer = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: center;
`;
