import React, { useContext, useEffect, useState } from 'react';
import Modal from '@common/modal/Modal';
import { SubsystemIOCaseStudyProductOut, UnitGroupList } from '@typeList/types';
import { useCcalculateMathFormula, useGetUnitGroups } from '@hooks/useBackendApi';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import { getCurrentUserInfo } from '@services/Cookies';
import styled from 'styled-components';

import iconEdit from '@images/product_system/icon_edit.svg'
import iconReload from '@images/button/icon_reload.svg';
import { useTranslation } from 'react-i18next';
import { RequiredSpan } from '@styles/Common';
import { formatNumber, checkFormula } from '@utils/utils';
import FlowParameterSettingListModal from '@specific/process/flow_parameter_setting/FlowParameterSettingListModal';
import { useUnitHooks } from '@hooks/useUnitHook';

interface SubsystemCaseStudyProductEditModalType {
    subsystemName: string;
    currentNodeData: SubsystemIOCaseStudyProductOut;
    direction: string;
    onSave: (updatedIO: SubsystemIOCaseStudyProductOut) => void;
}

const SubsystemCaseStudyProductEditModal: React.FC<SubsystemCaseStudyProductEditModalType> = ({
    subsystemName,
    currentNodeData,
    direction,
    onSave
}) => {
    const {t} = useTranslation();
    const context = useContext(CaseStudyViewContext);
    const { userData, unitGroupsData, flowState, unitConversionsData, caseStudyData } = context;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const closeInitModal = () => {
        setInputProductName(currentNodeData?.exchange.name || "");
        setInputAmount(
            formatNumber(
                currentNodeData?.amount ?? 0,
                userData?.significant_figures ?? 2,
                userData?.use_decimal_notation ?? false,
                userData?.decimal_places ?? 2
            ) ?? null
        );
        setInputAmountFormula(currentNodeData?.formula || "");
        setInputPublicComment(currentNodeData?.public_comment || "");
        setInputPrivateComment(currentNodeData?.private_comment || "");
        setInputUnitId(String(currentNodeData?.unit.id) || "0");
        setInputUnitName(currentNodeData?.unit.name || "");
        setInputUnitCode("");
        setInputUnitLocale("");
        setInputUnitPrivateComment(null);
        setInputUnitPublicComment(null);
        setInputCompatibleProductName(currentNodeData?.compatible_product_name || "");
        setInputAmountComment(currentNodeData?.amount_comment || "");
        setInputInformationSources(currentNodeData?.information_sources || "");
        setInputCompatibilityLevel(currentNodeData?.compatibility_level || 0);

        // モーダルを閉じる
        setIsModalOpen(false);
    };
    

    const [inputProductName, setInputProductName] = useState<string>(currentNodeData?.exchange.name || "");
    // const [inputAmount, setInputAmount] = useState<number>(currentNodeData?.amount ?? 0);
    const [inputAmount, setInputAmount] = useState<number | string | null>(
        formatNumber(
            currentNodeData?.amount ?? 0,
            userData?.significant_figures ?? 2,
            userData?.use_decimal_notation ?? false,
            userData?.decimal_places ?? 2
        ) ?? null // 初期値を null として管理
    );
    useEffect(() =>{
        // 編集後に再度開くと変換されないのでダイアログ展開時にもセットする
        if(isModalOpen) {
            setInputAmount(
                formatNumber(
                    currentNodeData?.amount ?? 0,
                    userData?.significant_figures ?? 2,
                    userData?.use_decimal_notation ?? false,
                    userData?.decimal_places ?? 2
                ) ?? null // 初期値を null として管理
            )
        }
    },[isModalOpen])

    const [inputAmountFormula, setInputAmountFormula] = useState<string | undefined>(currentNodeData?.formula || "");
    const [inputPublicComment, setInputPublicComment] = useState<string>(currentNodeData?.public_comment || "");
    const [inputPrivateComment, setInputPrivateComment] = useState<string>(currentNodeData?.private_comment || "");
    const [inputUnitId, setInputUnitId] = useState<string>(String(currentNodeData?.unit.id) || "0");
    const [inputUnitName, setInputUnitName] = useState<string>(currentNodeData?.unit.name || "");
    const [inputUnitCode, setInputUnitCode] = useState<string>("");
    const [inputUnitLocale, setInputUnitLocale] = useState<string>("");
    const [inputUnitPrivateComment, setInputUnitPrivateComment] = useState<string | null>(null);
    const [inputUnitPublicComment, setInputUnitPublicComment] = useState<string | null>(null);
    const [inputCompatibleProductName, setInputCompatibleProductName] = useState<string>(currentNodeData?.compatible_product_name || "");
    const [inputAmountComment, setInputAmountComment] = useState<string>(currentNodeData?.amount_comment || "");
    const [inputInformationSources, setInputInformationSources] = useState<string>(currentNodeData?.information_sources || "");
    const [inputCompatibilityLevel, setInputCompatibilityLevel] = useState<number>(currentNodeData?.compatibility_level || 0);
    const [unitList, setUnitList] = useState<UnitGroupList[]>([]);

    // エラーチェック
    const [productNameError, setProductNameError] = useState<any>(null);
    const [amountError, setAmountError] = useState<any>(null);
    const [amountFormulaError, setAmountFormulaError] = useState<any>(null);
    const validateCheck = (): boolean => {
        setProductNameError(null);
        setAmountError(null);
        setAmountFormulaError(null);
        if (!inputProductName) {
            setProductNameError(t('製品名は必須です'));
        }
        if (inputAmount === null || inputAmount === undefined) {
            setAmountError(t('流量は必須です'));
        }
        if (!checkFormula(inputAmountFormula)) {
            setAmountFormulaError(t('数式に使えない文字が混ざっています。使える文字は、英数と._+-*/()です'));
        }        
        if (!inputProductName || inputAmount === null || inputAmount === undefined || !checkFormula(inputAmountFormula)) {
            console.log("validateCheck false")
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (unitGroupsData) {
            setUnitList(unitGroupsData);
        }
    }, [unitGroupsData]);

    const { applyUnitConversion } = useUnitHooks();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        const value = event.target.value;
        switch (key) {
            case "name":
                setInputProductName(value);
                break;
            case "amount":
                if (value === "") {
                    setInputAmount(null); // 空白の場合は null として扱う
                } else {
                    setInputAmount(Number(value)); // 数値として管理する
                }
                break;
            case "amount_formula":
                setInputAmountFormula(value);
                break;
            case "compatible_product_name":
                setInputCompatibleProductName(value);
                break;
        }
    }

    const handleInputChangeTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>, key: string) => {
        switch (key) {
            case "public_comment":
                setInputPublicComment(event.target.value);
                break;
            case "private_comment":
                setInputPrivateComment(event.target.value);
                break;
            case "amount_comment":
                setInputAmountComment(event.target.value);
                break;
            case "information_sources":
                setInputInformationSources(event.target.value);
                break;
        }
    }

    const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>, key: string) => {
        switch (key) {
            case "unit":
                const selectedValue = event.target.value;
                setInputUnitId(selectedValue);
                for (const group of unitList ?? []) {
                    const selectedUnit = group.units.find(unit => unit.id === Number(selectedValue));
                    if (selectedUnit) {
                        setInputUnitName(selectedUnit.name);
                        setInputUnitCode(selectedUnit.code);
                        setInputUnitLocale(selectedUnit.locale);
                        setInputUnitPrivateComment(selectedUnit.private_comment);
                        setInputUnitPublicComment(selectedUnit.public_comment);
                        break;
                    }
                }

                // 単位換算の適用
                const newAmount = applyUnitConversion(
                    Number(inputAmount),
                    Number(inputUnitId),
                    Number(selectedValue),
                    unitConversionsData,
                );
                setInputAmount(Number(newAmount));

                break;
            case "compatibility_level":
                setInputCompatibilityLevel(Number(event.target.value));
                break
        }
    }

    const handleClickOK = async () => {
        if(!validateCheck()){
            return
        }
        const updatedParam: SubsystemIOCaseStudyProductOut = {
            ...param,
            exchange: {
                ...param.exchange,
            }
        };

        onSave(updatedParam);
        closeModal();
    }

    const param: SubsystemIOCaseStudyProductOut = {
        locale: currentNodeData?.locale,
        compatible_product_name: inputCompatibleProductName,
        public_comment: inputPublicComment,
        private_comment: inputPrivateComment,
        information_sources: inputInformationSources,
        amount_comment: inputAmountComment,
        process_io_no: currentNodeData?.process_io_no,
        formula: inputAmountFormula || "",
        amount: Number(inputAmount) || 0,
        direction: direction,
        compatibility_level: inputCompatibilityLevel,
        id: currentNodeData?.id,
        global_id: currentNodeData?.global_id,
        unit: {
            id: Number(inputUnitId),
            name: inputUnitName || ""
        },
        type: "case_study_product",
        exchange: {
            locale: currentNodeData?.exchange.locale,
            name: inputProductName,
            synonyms_text: currentNodeData?.exchange.synonyms_text,
            include_items_text: currentNodeData?.exchange.include_items_text,
            related_items_text: currentNodeData?.exchange.related_items_text,
            public_comment: currentNodeData?.exchange.public_comment,
            private_comment: currentNodeData?.exchange.private_comment,
            id: currentNodeData?.exchange.id,
            unit_id: currentNodeData?.exchange.unit_id,
            case_study_product_no: currentNodeData?.exchange.case_study_product_no
        },
        upper_subsystem_output_no: 0,
    }

    // 連結先のサブシステム（出力は下流、入力は上流を取得する）
    const [nodeName, setNodeName] = useState<string>("");
    const getSubsystemConcatenation = async () => {
        // currentNodeDataのprocess_io_noを取得
        const currentProcessIoNo = currentNodeData.process_io_no.toString(); // process_io_noを文字列に変換
    
        if (direction === "in") {
            // targetHandleがcurrentProcessIoNoと一致するedgeを探す
            const matchingEdge = flowState.edges.find(
                (edge) => edge.targetHandle && edge.targetHandle.split('-')[1] === currentProcessIoNo
            );
    
            if (matchingEdge) {
                // edgeのsourceを使って対応するノードを探し、そのnameを取得
                const matchingNode = flowState.nodes.find(
                    (node) => node.id === matchingEdge.source
                );
                setNodeName(matchingNode?.data?.name || "");
            } else {
                setNodeName("");
            }
        }
    
        if (direction === "out") {
            // sourceHandleがcurrentProcessIoNoと一致するedgeを探す
            const matchingEdge = flowState.edges.find(
                (edge) => edge.sourceHandle && edge.sourceHandle.split('-')[1] === currentProcessIoNo
            );
    
            if (matchingEdge) {
                // edgeのtargetを使って対応するノードを探し、そのnameを取得
                const matchingNode = flowState.nodes.find(
                    (node) => node.id === matchingEdge.target
                );
                setNodeName(matchingNode?.data?.name || "");
            } else {
                setNodeName("");
            }
        }
    };

    useEffect(() =>{
        getSubsystemConcatenation();
    },[flowState])


    // 今すぐ評価する
    const { getCalculateMathFormula, calculateMathFormulaData, calculateMathFormulaError, calculateMathFormulaLoading } = useCcalculateMathFormula();
    const hundleEvaluateNow = async () => {
        // 入出力追加
        try {
            await getCalculateMathFormula(inputAmountFormula ?? "", caseStudyData?.case_study_parameters ?? [])
        } catch (error: any) {
            console.log(error)
            return;
        }
    }

    useEffect(() =>{
        if(calculateMathFormulaData && !calculateMathFormulaError) {
            if(calculateMathFormulaData.result_value) {
                setInputAmount(calculateMathFormulaData.result_value)
                setAmountFormulaError(null);
            }
            if(calculateMathFormulaData.error_message) {
                setAmountFormulaError(calculateMathFormulaData.error_message)
            }
        }
    },[calculateMathFormulaData])

    return (
        <>
            <NodeBoxDlButton onClick={openModal} type='button' >
                <NodeBoxFlowButtonIconDiv>
                    <NodeBoxFlowButtonIcon></NodeBoxFlowButtonIcon>
                </NodeBoxFlowButtonIconDiv>
            </NodeBoxDlButton>
            <Modal isOpen={isModalOpen} onClose={closeInitModal}>
                <Inner>
                    <Title>{t('製品システム：フローおよび製品の編集（未登録の製品）')}</Title>
                    <Section>
                        <Dl>
                            <DlWrap>
                                <Dt>{t('製品名')}<RequiredSpan>※</RequiredSpan></Dt>
                                <Dd>
                                    <Input
                                        type="text"
                                        onChange={(event) => handleInputChange(event, 'name')}
                                        value={inputProductName ?? ''}
                                    />
                                    {productNameError && <ErrorMsg>{productNameError}</ErrorMsg>}
                                </Dd>
                            </DlWrap>
                            <DlWrap>
                                <Dt>{t('流量の数式')}</Dt>
                                <Dd>
                                    <Input
                                        type="text"
                                        value={inputAmountFormula ?? ""}
                                        onChange={(event) => handleInputChange(event, 'amount_formula')}
                                    />
                                    <FlowParameterSettingListModal inputAmountFormula={inputAmountFormula} setInputAmountFormula={setInputAmountFormula} />
                                </Dd>
                                {amountFormulaError && <><Dd></Dd><ErrorMsg>{amountFormulaError}</ErrorMsg></>}
                            </DlWrap>
                            <DlWrap>
                                <Dt></Dt>
                                <Dd>
                                    {t('*数式はケーススタディ保存時に評価され流量に反映されます')}
                                    {userData?.is_admin &&
                                        <SectionColumnContentLink onClick={() => { hundleEvaluateNow() }}>[SystemTest]今すぐ評価する</SectionColumnContentLink>
                                    }
                                </Dd>
                            </DlWrap>
                            <DlWrap>
                                <Dt>{t('流量')}<RequiredSpan>※</RequiredSpan></Dt>
                                <DdShort>
                                    <AmountInput
                                        type="number"
                                        value={inputAmount !== null && inputAmount !== undefined ? inputAmount : ''}
                                        min={"0"}
                                        onChange={(event) => handleInputChange(event, 'amount')}
                                        disabled={!!inputAmountFormula} 
                                    />
                                    <UnitSelectLabel>
                                        <UnitSelect
                                            value={inputUnitId ?? ''}
                                            onChange={(event) => handleInputChangeSelect(event, 'unit')}
                                        >
                                            {unitList?.map((units) => (
                                                units.units.map((unit) => (
                                                    <option key={unit.id} value={unit.id}>
                                                        {unit.name}
                                                    </option>
                                                ))
                                            ))}
                                        </UnitSelect>
                                    </UnitSelectLabel>
                                    {amountError && <ErrorMsg>{amountError}</ErrorMsg>}
                                </DdShort>
                            </DlWrap>

                            <DlWrap>
                                <Dt>{t('上流サブシステム')}</Dt>
                                <Dd>
                                    <DisabledInput
                                        type="text"
                                        disabled
                                        value={direction === "out" ? subsystemName: nodeName}
                                    />
                                </Dd>
                            </DlWrap>
                            <DlWrap>
                                <Dt>{t('下流サブシステム')}</Dt>
                                <Dd>
                                    <DisabledInput
                                        type="text"
                                        disabled
                                        value={direction === "in" ? subsystemName: nodeName}
                                    />
                                </Dd>
                            </DlWrap>
                        </Dl>
                    </Section>
                    <Section>
                        <Title>{t('適合性')}</Title>
                        <Dl>
                            <DlWrap>
                                <Dt>{t('情報源のフロー名')}</Dt>
                                <Dd>
                                    <Input
                                        type="text"
                                        value={inputCompatibleProductName ?? ''}
                                        onChange={(event) => handleInputChange(event, 'compatible_product_name')}
                                    />
                                </Dd>
                            </DlWrap>
                            <DlWrap>                                
                                <Dt>{t('適合度')}</Dt>
                                <SelectLabel>
                                    <Select
                                        onChange={(event) => handleInputChangeSelect(event, 'compatibility_level')}
                                        value={inputCompatibilityLevel ?? "1"}
                                    >
                                        <option value="1">{t('よい')}</option>
                                        <option value="2">{t('普通')}</option>
                                        <option value="3">{t('悪い')}</option>
                                    </Select>
                                </SelectLabel>
                            </DlWrap>
                        </Dl>
                    </Section>
                    <Section>
                        <Title>{t('コメント')}</Title>
                        <Dl>
                            <DlWrap>
                                <Dt>{t('プライベートコメント')}</Dt>
                                <Dd>
                                    <TextArea
                                        cols={100}
                                        rows={3}
                                        onChange={(event) => handleInputChangeTextArea(event, 'private_comment')}
                                        value={inputPrivateComment ?? ''}
                                    />
                                </Dd>
                            </DlWrap>
                            <DlWrap>
                                <Dt>{t('公開コメント')}</Dt>
                                <Dd>
                                    <TextArea
                                        cols={100}
                                        rows={3}
                                        onChange={(event) => handleInputChangeTextArea(event, 'public_comment')}
                                        value={inputPublicComment ?? ''}
                                    />
                                </Dd>
                            </DlWrap>
                            <DlWrap>
                                <Dt>{t('情報源')}</Dt>
                                <Dd>
                                    <TextArea
                                        cols={50}
                                        rows={2}
                                        onChange={(event) => handleInputChangeTextArea(event, 'information_sources')}
                                        value={inputInformationSources ?? ''}
                                    />
                                </Dd>
                            </DlWrap>
                            <DlWrap>
                                <Dt>{t('流量の計算方法')}</Dt>
                                <Dd>
                                    <TextArea
                                        cols={50}
                                        rows={2}
                                        onChange={(event) => handleInputChangeTextArea(event, 'amount_comment')}
                                        value={inputAmountComment ?? ''}
                                    />
                                </Dd>
                            </DlWrap>
                        </Dl>
                    </Section>
                    <ButtonWrapper>
                        <UpdateButton type='button' onClick={handleClickOK}>
                            <ButtonIconWrapper>
                                <ButtonIcon></ButtonIcon>
                            </ButtonIconWrapper>
                            <ButtonText>{t('変更する')}</ButtonText>
                        </UpdateButton>
                    </ButtonWrapper>
                </Inner>
            </Modal>
        </>
    )
}

export default SubsystemCaseStudyProductEditModal;

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const NodeBoxDlButton = styled.button`
  white-space: nowrap;
  cursor: pointer;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
`

const NodeBoxFlowButtonIconDiv = styled.div`
`

const NodeBoxFlowButtonIcon = styled.span`
mask: url(${iconEdit}) no-repeat center center / contain;
-webkit-mask: url(${iconEdit}) no-repeat center center / contain;
display: block;
width: 24px;
height: 24px;
background: var(--color-txt-primary);
`

const Title = styled.h2`
font-size: 18px;

padding: 0 0 20px;
margin-bottom: 20px;
border-bottom: 1px solid var(--color-line-primary);
font-weight: 500;

line-height: 1.25;
`

const Section = styled.div`
    margin-bottom: 60px;
`

const Dl = styled.dl`
`

const DlWrap = styled.div`
margin-bottom: 8px;

display: grid;
align-items: center;
grid-template-columns: 27.0833333333% minmax(0, 1fr);
gap: 6px 40px;
`

const Dt = styled.dt`
margin-bottom: 0;
font-size: 16px;
font-weight: 500;
line-height: 1.25;
`
const Dd = styled.dd`
display: flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
gap: 16px;
`
const DdShort = styled.dd`
width: min(100%, 340px);

display: flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
gap: 16px;
`

const Input = styled.input`
width: 81.6176470588%;

padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const AmountInput = styled.input<{ disabled?: boolean }>`
width: 50%;

padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: ${(props) => (props.disabled ? 'var(--color-bg-primary)' : '#fff')};
font-size: 16px;
font-weight: 400;
line-height: 1.25;
&:disabled {
    pointer-events: none;
}
`

const FlowNameInput = styled.input`
width: min(100%, 340px);

padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const UnitSelectLabel = styled.label`
width: 50%;
position: relative;
display: block;
`
const UnitSelect = styled.select`
color: rgb(85, 85, 85);

position: relative;
width: 100%;
padding: 10px 32px 10px 12px;;
cursor: pointer !important;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const SelectLabel = styled.label`
width: min(100%, 340px);
position: relative;
display: block;
`

const Select = styled.select`
color: rgb(85, 85, 85);

position: relative;
width: 100%;
padding: 12px 32px 12px 12px;
cursor: pointer !important;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const DownInput = styled.input`
width: 100%;
background-color: var(--color-bg-primary);

padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const TextArea = styled.textarea`
padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
width: 100%;
`

const ButtonWrapper = styled.div`
padding: 8px 24px;
text-align: right;
`
const Button = styled.button`
padding: 8px 24px;
border-radius: 20px;

display: inline-flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: center;
cursor: pointer;
white-space: nowrap;
background-color: var(--color-gray--01);
gap: 0 4px;

-moz-appearance: none;
appearance: none;
border: none;
font: inherit;
outline: none;

&:hover {
    background-color: var(--color-site-secondary);
    text-decoration: none !important;

    div span {
        background-color: #fff;
    }
    span {
        color: #fff;
    }
}
`

const UpdateButton = styled.button`
padding: 2px 12px;
border-radius: 20px;

display: inline-flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: center;
cursor: pointer;
white-space: nowrap;
background-color: var(--color-gray--01);
gap: 0 4px;

-moz-appearance: none;
appearance: none;
border: none;
font: inherit;
outline: none;

&:hover {
    background-color: var(--color-site-secondary);
    text-decoration: none !important;

    div span {
        background-color: #fff;
    }
    span {
        color: #fff;
    }
}
`

const ButtonIconWrapper = styled.div`
`
const ButtonIcon = styled.span`
background-color: var(--color-site-secondary);

mask: url(${iconReload}) no-repeat center center / contain;
-webkit-mask: url(${iconReload}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 24px;
height: 24px;
`
const ButtonText = styled.span`
font-size: 12px;
font-weight: 500;
`

const ErrorMsg = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: var(--color-error-primary);
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
`;


const DisabledInput = styled.input`
    width: 81.6176470588%;

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: var(--color-bg-primary);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const SectionColumnContentLink = styled.a`
    text-decoration: underline;
`