import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
    SortingState,
} from '@tanstack/react-table';
import Modal from '@common/modal/Modal';  // モーダルコンポーネントのインポート
import { EditDataTableType } from '@typeList/types';
import DraggableRow from './DraggableRow';
import DraggableHeader from './DraggableHeader';


import iconEdit from '@images/table/icon_edit.svg';
import iconArrowLeft from '@images/table/icon_arrow_left.svg';
import iconArrowRight from '@images/table/icon_arrow_right.svg';
import DeleteIconButton from '@common/button/DeleteIconButton';
import { useTranslation } from 'react-i18next';
import { useDeleteProduct } from '@hooks/useBackendApi';
import { use } from 'i18next';

interface TableProps {
    columns: ColumnDef<any, any>[];
    data: any[];
    ModalComponent: React.FC<EditDataTableType>;
    handleIsModalOpen: (isModalOpen: boolean) => void;
}

const initialPageIndex = 0;
const initialPageSize = 100;

const ProductsEditDataTable: React.FC<TableProps> = ({ columns: initialColumns, data, ModalComponent, handleIsModalOpen }) => {
    const { t } = useTranslation();
    const [columns, setColumns] = useState<ColumnDef<any, any>[]>([
        {
            id: 'edit',
            header: () => t('編集'),
            cell: ({ row }) => (
                <EditButton type='button' onClick={() => handleOpenModal(row.original.id)}>
                    <ButtonInner>
                        <EditButtonIcon></EditButtonIcon>
                    </ButtonInner>
                </EditButton>
            ),
            enableSorting: false, // ソート不可にする
        },
        {
            id: 'delete',
            header: () => t('削除'),
            cell: ({ row }) => (
                <>
                    {!row.original.is_database_pack_master && 
                        <DeleteIconButton onClick={() => { handleDelete(row.original.id) }}></DeleteIconButton>
                    }
                </>
            ),
            enableSorting: false, // ソート不可にする
        },
        ...initialColumns,
    ]);
    const [rows, setRows] = useState(data);
    useEffect(() => {
        setRows(data);
    }, [data])
    const [sorting, setSorting] = useState<SortingState>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentRowId, setCurrentRowId] = useState<string | null>(null);

    const handleOpenModal = (id: string) => {
        setCurrentRowId(id);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setCurrentRowId(null);
        handleIsModalOpen(true);
    };

    // 製品削除
    const { deleteProduct } = useDeleteProduct();
    const handleDelete = async (id: number) => {
        const isConfirmed = window.confirm(t('削除しますか？'));
        if (isConfirmed) {
            try {
                // await api
                await deleteProduct(id);
                // await window.location.reload();
                await setRows(prevData => prevData.filter(row => row.id !== id));
            } catch (error: any) {
                alert(error.response.data.detail);
            }
        }
    }

    const moveRow = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const newRows = [...rows];
            const [movedRow] = newRows.splice(dragIndex, 1);
            newRows.splice(hoverIndex, 0, movedRow);
            setRows(newRows);
        },
        [rows]
    );

    const moveColumn = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const newColumns = [...columns];
            const [movedColumn] = newColumns.splice(dragIndex, 1);
            newColumns.splice(hoverIndex, 0, movedColumn);
            setColumns(newColumns);
        },
        [columns]
    );

    const table = useReactTable({
        data: rows,
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        initialState: {
            pagination: {
                pageIndex: initialPageIndex,
                pageSize: initialPageSize,
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const topScrollRef = useRef<HTMLDivElement>(null);
    const bodyScrollRef = useRef<HTMLDivElement>(null);
    const bottomScrollRef = useRef<HTMLDivElement>(null);
    const [tableWidth, setTableWidth] = useState(0);

    useEffect(() => {
        if (bodyScrollRef.current) {
            setTableWidth(bodyScrollRef.current.scrollWidth); // テーブル全体の幅を取得
        }

        const syncScroll = (source: HTMLDivElement, target: HTMLDivElement) => {
            source.addEventListener('scroll', () => {
                target.scrollLeft = source.scrollLeft;
            });
        };

        // 上下スクロールバーと本体を同期
        if (topScrollRef.current && bodyScrollRef.current && bottomScrollRef.current) {
            syncScroll(topScrollRef.current, bodyScrollRef.current);
            syncScroll(bodyScrollRef.current, topScrollRef.current);
            syncScroll(bottomScrollRef.current, bodyScrollRef.current);
            syncScroll(bodyScrollRef.current, bottomScrollRef.current);
        }

        return () => {
            // イベントリスナーのクリーンアップ
            if (topScrollRef.current && bodyScrollRef.current && bottomScrollRef.current) {
                topScrollRef.current.onscroll = null;
                bodyScrollRef.current.onscroll = null;
                bottomScrollRef.current.onscroll = null;
            }
        };
    }, []);

    return (
        <>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                <ModalComponent Id={Number(currentRowId)} onClose={handleCloseModal} selectted_database_pack_id={null} />
            </Modal>

            <SectionTableWrap>

                {/* 上部スクロールバー */}
                <TopScroll className="top-scroll" ref={topScrollRef}>
                    <div style={{ width: `${tableWidth}px` }}></div>
                </TopScroll>

                {/* テーブル本体 */}
                <ScrollableBody className="scrollable-body" ref={bodyScrollRef}>
                    <Table>
                        <Thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <Tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header, index) => (
                                        <DraggableHeader
                                            key={header.id}
                                            header={header}
                                            index={index}
                                            moveColumn={moveColumn}
                                        />
                                    ))}
                                </Tr>
                            ))}
                        </Thead>
                        <Tbody>
                            {table.getRowModel().rows.map((row, index) => (
                                <DraggableRow key={row.id} row={row} index={index} moveRow={moveRow} />
                            ))}
                        </Tbody>
                    </Table>
                </ScrollableBody>

                {/* 下部スクロールバー */}
                <ScrollableBody className="bottom-scroll" ref={bottomScrollRef}>
                    <div style={{ width: `${tableWidth}px` }}></div>
                </ScrollableBody>

            </SectionTableWrap>
        </>
    );
};

export default ProductsEditDataTable;

const SectionTableWrap = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`;

const TopScroll = styled.div`
    overflow-x: auto;
    overflow-y: hidden;
    height: 16px; /* スクロールバーの高さを指定 */
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        height: 8px;
    }
`;

const ScrollableBody = styled.div`
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        height: 8px;
    }
`;

const EditButton = styled.button`
    cursor: pointer;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    outline: none;
`;

const ButtonInner = styled.div`
`;

const EditButtonIcon = styled.span`
    mask: url(${iconEdit}) no-repeat center center / contain;
    -webkit-mask: url(${iconEdit}) no-repeat center center / contain;
    display: block;
    width: 24px;
    height: 24px;
    background: var(--color-txt-primary);
`;

const Table = styled.table`
    min-width: 100%;
    border-collapse: collapse;
    margin-bottom: 8px;
    background-color: #fff;
    border-spacing: 0;
`

const Thead = styled.thead`
    border-right: 1px solid var(--color-line-primary);
    border-left: 1px solid var(--color-line-primary);
`

const Tr = styled.tr`
    border-top: 1px solid var(--color-line-primary);
    border-bottom: 1px solid var(--color-line-primary);
`

const Tbody = styled.tbody`
    border-right: 1px solid var(--color-line-primary);
    border-left: 1px solid var(--color-line-primary);
`;


