import {
	useCaseStudyLciaResults,
	useCaseStudyLciaResultsCategory,
	useCaseStudyLciaResultsUpper,
	useFileUpload,
	useGetAttachedFileBase64Image,
	useGetCaseStudy,
	useGetCaseStudyCalculatedDate,
	useGetCaseStudyLciResults,
	useGetCaseStudyLciResultsCategory,
	useGetCaseStudyLciResultsUpper,
	useGetLciaModelsForEndpoint,
	useGetLciaModelsForIntegration,
	useGetLciaModelsForMidpoint,
	useGetReviewLevelType,
	useGetSubsystemCategorySet,
	useGetUnitConversions,
	useGetUnitGroups,
	useGetUserDetail,
	useSaveAsNewCaseStudy,
	useUpdateCaseStudy
} from '@hooks/useBackendApi';
import { A, BaseArea } from '@styles/Common'
import React, { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
	ArrowElement,
	CalculatedDateOut,
	CaseStudyCalculate,
	CaseStudyLCIAResults,
	CaseStudyParametersrameters,
	GetCaseStudy,
	GetUserDetail,
	ImpactAssesmentMethods,
	ProductSystemDiagram,
	ReviewLevelType,
	SubsystemCategorySet,
	SubsystemElement,
	SubsystemIOCaseStudyProductIn,
	SubsystemIOCaseStudyProductOut,
	SubsystemIOCaseStudyProductOutExchange,
	SubsystemIODefinedProductIn,
	SubsystemIODefinedProductOut,
	SubsystemIOElementaryFlowIn,
	SubsystemIOElementaryFlowOut,
	UnitConversionsList,
	UnitGroupList,
	UpdateCaseStudy,
	UpdateSubsystems
} from '@typeList/types';
import CaseStudyInfo from '@specific/case_study/case_study_view/CaseStudyInfo';
import CaseStudyParameterSetting from '@specific/case_study/case_study_view/CaseStudyParameterSetting';
import ProductSystem from '@specific/case_study/case_study_view/ProductSystem';
import Report from '@specific/case_study/case_study_view/Report';
import CaseStudyCalculation from '@specific/case_study/case_study_view/CaseStudyCalculation';
import { Edge, MarkerType, Node, useEdgesState, useNodesState } from 'reactflow';
import useUndoable, { Options } from 'use-undoable';
import { useTranslation } from 'react-i18next';
import { checkPrameterName, extractNumberFromHandle } from '@utils/utils';
import { getCurrentUserInfo } from '@services/Cookies';
import ErrorMsg from '@common/error/ErrorMsg';
import { FadeLoader } from 'react-spinners';
import MessageDaialog from '@common/modal/MessageDaialog';
import AlertDialogDisable from '@common/modal/AlertDialogDisable';
import LoadingDialog from '@common/modal/LoadingDialog';
import { use } from 'i18next';

// ケーススタディコンテキスト
export interface FlowState {
	nodes: Node[];
	edges: Edge[];
}

interface FileItem {
	id: number;
	is_product_system_summary: boolean;
	is_system_boundary: boolean;
}

interface CaseStudyViewContextType {
	userData: GetUserDetail | null;
	unitGroupsData: UnitGroupList[] | null;
	caseStudyData: GetCaseStudy | null;
	resetCaseStudyData: () => void;
	setUnsaved: Dispatch<SetStateAction<boolean>>;
	databasePacks: number | undefined;
	reviewLevelTypeData: ReviewLevelType[] | null;
	selectReviewLevelData: any[] | undefined;
	reviewLevelsDataList: any[] | undefined;
	setSelectReviewLevelData: Dispatch<SetStateAction<any[] | undefined>>;
	setReviewLevelsDataList: Dispatch<SetStateAction<any[] | undefined>>;
	caseStudyParameters: CaseStudyParametersrameters[] | undefined;
	updateCaseStudyParameters: CaseStudyParametersrameters[] | undefined;
	setCaseStudyParameters: Dispatch<SetStateAction<CaseStudyParametersrameters[] | undefined>>;
	setUpdateCaseStudyParameters: Dispatch<SetStateAction<CaseStudyParametersrameters[] | undefined>>;
	nodes: Node<any, string | undefined>[];
	edges: Edge<any>[];
	setNodes: React.Dispatch<React.SetStateAction<Node<any, string | undefined>[]>>;
	setEdges: React.Dispatch<React.SetStateAction<Edge<any>[]>>;
	subsystemCategorySetData: SubsystemCategorySet[] | null;
	setSubsystemCategorySetData: Dispatch<SetStateAction<SubsystemCategorySet[] | null>>;
	flowState: FlowState;
	setFlowState: Dispatch<SetStateAction<FlowState>>;
	undo: () => void;
	canUndo: boolean;
	redo: () => void;
	canRedo: boolean;
	midpointData: ImpactAssesmentMethods[] | null;
	endpointData: ImpactAssesmentMethods[] | null;
	integrationData: ImpactAssesmentMethods[] | null;
	inputCharacterizationGroupingValue: string;
	setInputCharacterizationGroupingValue: Dispatch<SetStateAction<string>>;
	inputCharacterizationDisplayFormatValue: string;
	setInputCharacterizationDisplayFormatValue: Dispatch<SetStateAction<string>>;
	inputDsplayFormatValue: string;
	setInputDisplayFormatValue: Dispatch<SetStateAction<string>>;
	inputGroupingValue: string;
	setInputGroupingValue: Dispatch<SetStateAction<string>>;
	selectCategorySet: number;
	setSelectCategorySet: Dispatch<SetStateAction<number>>;
	selectCategorySetList: Array<number>;
	setSelectCategorySetList: Dispatch<SetStateAction<Array<number>>>;
	categorySetRows: Array<{ id: number, inputCategoryId: number, inputCategoryName: string, selectedCategoryValue: string, selectedCategoryValueId: number, scope: string}>;
	setCategorySetRows: Dispatch<SetStateAction<Array<{ id: number, inputCategoryId: number, inputCategoryName: string, selectedCategoryValue: string, selectedCategoryValueId: number, scope: string }>>>;
	previewImage: string | null;
	setPreviewImage: Dispatch<SetStateAction<string | null>>;
	imageFile: File | undefined;
	setImageFile: Dispatch<SetStateAction<File | undefined>>;
	imageSrc: string | null;
	setImageSrc: Dispatch<SetStateAction<string | null>>;
	imageName: string | null;
	setImageName: Dispatch<SetStateAction<string | null>>;
	previewSystemBoundaryImage: string | null;
	setPreviewSystemBoundaryImage: Dispatch<SetStateAction<string | null>>;
	systemBoundaryimageSrc: string | null;
	setSystemBoundaryImageSrc: Dispatch<SetStateAction<string | null>>;
	systemBoundaryimageName: string | null;
	setSystemBoundaryImageName: Dispatch<SetStateAction<string | null>>;
	systemBoundaryimageFile: File | undefined;
	setSystemBoundaryImageFile: Dispatch<SetStateAction<File | undefined>>;
	onSubmit: SubmitHandler<UpdateCaseStudy>;
	hasComparisonClaim: boolean | null;
	setHasComparisonClaim: Dispatch<SetStateAction<boolean | null>>;
	changeCheckSubsystem: boolean;
	setChangeCheckSubsystem: Dispatch<SetStateAction<boolean>>;
	changeCheckInfo: boolean;
	setChangeCheckInfo: Dispatch<SetStateAction<boolean>>;
	changeCheckReviewLevel: boolean;
	setChangeCheckReviewLevel: Dispatch<SetStateAction<boolean>>;
	changeCheckProcessReviewLevel: boolean;
	setChangeCheckProcessReviewLevel: Dispatch<SetStateAction<boolean>>;
	changeCheckParameter: boolean;
	setchangeCheckParameter: Dispatch<SetStateAction<boolean>>;
	changeCheckReport: boolean;
	setchangeCheckReport: Dispatch<SetStateAction<boolean>>;
	selectCharacterizationModelIdList: number[];
	setSelectCharacterizationModelIdList: React.Dispatch<React.SetStateAction<number[]>>
	selectCharacterizationCategorySet: number;
	setSelectCharacterizationCategorySet: React.Dispatch<React.SetStateAction<number>>
	caseStudyLciaResultsData: CaseStudyLCIAResults | null
	caseStudyLciaResultsCategoryData: CaseStudyLCIAResults | null
	caseStudyLciaResultsUpperData: CaseStudyLCIAResults | null
	caseStudyLciaResultsLoading: boolean
	caseStudyLciaResultsCategoryLoading: boolean
	caseStudyLciaResultsUpperLoading: boolean;
	lciResultsCategoryData: CaseStudyCalculate | null;
	lciResultsLoading: boolean;
	lciResultsUpperData: CaseStudyCalculate | null;
	lciResultsData: CaseStudyCalculate | null;
	lciResultsCategoryLoading: boolean;
	lciResultsUpperLoading: boolean;
	getInitLciData: () => Promise<void>;
	setSavedAlertEnabled: React.Dispatch<React.SetStateAction<boolean>>;
	calculateAndGetResultsLoading: boolean;
	setCalculateAndGetResultsLoading: React.Dispatch<React.SetStateAction<boolean>>;
	inputDamageAssessmentGroupingValue: string;
	setInputDamageAssessmentGroupingValue: React.Dispatch<React.SetStateAction<string>>;
	inputDamageAssessmentDisplayFormatValue: string;
	setInputDamageAssessmentDisplayFormatValue: React.Dispatch<React.SetStateAction<string>>;
	inputIntegrationGroupingValue: string;
	setInputIntegrationGroupingValue: React.Dispatch<React.SetStateAction<string>>;
	inputIntegrationDisplayFormatValue: string;
	setInputIntegrationDisplayFormatValue: React.Dispatch<React.SetStateAction<string>>;
	characterizationResultData: CaseStudyLCIAResults | null;
	inventoryAnalysData: CaseStudyCalculate | null;
	setInventoryAnalysData: React.Dispatch<React.SetStateAction<CaseStudyCalculate | null>>;
	// calculatedAt: string;
	// setCalculatedAt: React.Dispatch<React.SetStateAction<string>>;
	calculatedDateData: CalculatedDateOut | null;
	calculatedDateLoading: boolean;
	getLciCalculatedDate: () => Promise<void>;
	unitConversionsData: UnitConversionsList[] | null;
}


const defaulutContextvalue = {
	userData: null,
	unitGroupsData: null,
	caseStudyData: null,
	resetCaseStudyData: () => { },
	setUnsaved: () => { },
	databasePacks: 1,
	reviewLevelTypeData: [],
	selectReviewLevelData: [],
	reviewLevelsDataList: [],
	setSelectReviewLevelData: () => { },
	setReviewLevelsDataList: () => { },
	caseStudyParameters: [],
	updateCaseStudyParameters: [],
	setCaseStudyParameters: () => { },
	setUpdateCaseStudyParameters: () => { },
	nodes: [],
	edges: [],
	setNodes: () => { },
	setEdges: () => { },
	subsystemCategorySetData: [],
	setSubsystemCategorySetData: () => { },
	flowState: { nodes: [], edges: [] },
	setFlowState: () => { },
	undo: () => { },
	canUndo: false,
	redo: () => { },
	canRedo: false,
	midpointData: null,
	endpointData: null,
	integrationData: null,
	inputCharacterizationGroupingValue: "1",
	setInputCharacterizationGroupingValue: () => { },
	inputCharacterizationDisplayFormatValue: "1",
	setInputCharacterizationDisplayFormatValue: () => { },
	inputDsplayFormatValue: "1",
	setInputDisplayFormatValue: () => { },
	inputGroupingValue: "1",
	setInputGroupingValue: () => { },
	selectCategorySet: 0,
	setSelectCategorySet: () => { },
	selectCategorySetList: [],
	setSelectCategorySetList: () => { },
	categorySetRows: [],
	setCategorySetRows: () => { },
	previewImage: null,
	setPreviewImage: () => { },
	imageSrc: null,
	setImageSrc: () => { },
	imageName: null,
	setImageName: () => { },
	imageFile: undefined,
	setImageFile: () => { },
	previewSystemBoundaryImage: null,
	setPreviewSystemBoundaryImage: () => { },
	systemBoundaryimageSrc: null,
	setSystemBoundaryImageSrc: () => { },
	systemBoundaryimageName: null,
	setSystemBoundaryImageName: () => { },
	systemBoundaryimageFile: undefined,
	setSystemBoundaryImageFile: () => { },
	onSubmit: () => { },
	hasComparisonClaim: null,
	setHasComparisonClaim: () => { },
	changeCheckSubsystem: false,
	setChangeCheckSubsystem: () => { },
	changeCheckInfo: false,
	setChangeCheckInfo: () => { },
	changeCheckReviewLevel: false,
	setChangeCheckReviewLevel: () => { },
	changeCheckProcessReviewLevel: false,
	setChangeCheckProcessReviewLevel: () => { },
	changeCheckParameter: false,
	setchangeCheckParameter: () => { },
	changeCheckReport: false,
	setchangeCheckReport: () => { },
	selectCharacterizationModelIdList: [],
	setSelectCharacterizationModelIdList: () => { },
	selectCharacterizationCategorySet: 0,
	setSelectCharacterizationCategorySet: () => { },
	caseStudyLciaResultsData: null,
	caseStudyLciaResultsCategoryData: null,
	caseStudyLciaResultsUpperData: null,
	caseStudyLciaResultsLoading: false,
	caseStudyLciaResultsCategoryLoading: false,
	caseStudyLciaResultsUpperLoading: false,
	lciResultsCategoryData: null,
	lciResultsUpperData: null,
	lciResultsData: null,
	lciResultsLoading: false,
	lciResultsCategoryLoading: false,
	lciResultsUpperLoading: false,
	getInitLciData: async (): Promise<void> => { },
	setSavedAlertEnabled: () => { },
	calculateAndGetResultsLoading: false,
	setCalculateAndGetResultsLoading: () => { },
	inputDamageAssessmentGroupingValue: "1",
	setInputDamageAssessmentGroupingValue: () => { },
	inputDamageAssessmentDisplayFormatValue: "1",
	setInputDamageAssessmentDisplayFormatValue: () => { },
	inputIntegrationGroupingValue: "1",
	setInputIntegrationGroupingValue: () => { },
	inputIntegrationDisplayFormatValue: "1",
	setInputIntegrationDisplayFormatValue: () => { },
	characterizationResultData: null,
	inventoryAnalysData: null,
	setInventoryAnalysData: () =>{ },
	calculatedDateData: null,
	calculatedDateLoading: false,
	getLciCalculatedDate: async (): Promise<void> => { },
	unitConversionsData: [],
}
export const CaseStudyViewContext = createContext<CaseStudyViewContextType>(defaulutContextvalue);


/**
 * ケーススタディ情報入力画面 Base
 * @returns 
 */
const CaseStudyView = () => {
	// ユーザー情報
	const { userData } = useGetUserDetail(getCurrentUserInfo()?.id);

	// 初期データ取得フラグ
	const [initialDisplayFlag, setInitialDisplayFlag] = useState<boolean>(false)

	// 言語設定
	const { i18n, t } = useTranslation();
	useEffect(() => {
		let lng = userData?.default_locale_code;
		if (!lng) {
			lng = 'ja'
		}

		if (lng) {
			i18n.changeLanguage(lng);
			document.documentElement.lang = lng;
		}
	}, [userData]);

	const params = useParams();
	const { control, watch, register, handleSubmit, formState: { errors } } = useForm<UpdateCaseStudy>();

	// 選択されたタブを管理するステート
	const [activeTab, setActiveTab] = useState<string>('tab1');
	const handleTabClick = (tab: string) => {
		setActiveTab(tab);
	};

	// 保存状態のチェック
	const [unsaved, setUnsaved] = useState<boolean>(false);

	// ケーススタディ情報取得
	const { getCaseStudy, caseStudyData, caseStudyError, caseStudyLoading } = useGetCaseStudy();
	useEffect(() => {
		const fetchData = async () => {
			try {
				await getCaseStudy(Number(params.id));
			} catch (error: any) {
				console.log("error", error.response.data.detail);
			}
		};

		fetchData();
	}, [params])

	// 他人のケーススタディを展開させない
	const navigate = useNavigate();
	useEffect(() =>{
		if( userData && caseStudyData && (userData.username !== caseStudyData.created_by_username)) {
			navigate("/");		
		}
	},[userData, caseStudyData])

	// データベースパック
	const [databasePacks, setDatabasePacks] = useState<number>();
	useEffect(() => {
		setDatabasePacks(caseStudyData?.database_pack.id)
	}, [caseStudyData])

	// ケーススタディカテゴリセット
	const {
		getSubsystemCategorySet,
		subsystemCategorySetData,
		setSubsystemCategorySetData,
	} = useGetSubsystemCategorySet();

	useEffect(() => {
		try {
			if (caseStudyData) {
				getSubsystemCategorySet(caseStudyData.id);
			}
		} catch (error) {
			console.log("Error", error)
		}
	}, [caseStudyData])


	// カテゴリセット表示用
	const [categorySetRows, setCategorySetRows] = useState<Array<{ 
		id: number, 
		inputCategoryId: number, 
		inputCategoryName: string, 
		selectedCategoryValue: string, 
		selectedCategoryValueId: number,
		scope: string
	}>>([]);

	// 製品システムの選択中カテゴリセット
	const [selectCategorySet, setSelectCategorySet] = useState<number>(1);
	useEffect(() => {
		if (caseStudyData?.product_system_diagram.selected_subsystem_category_id) {
			setSelectCategorySet(caseStudyData?.product_system_diagram.selected_subsystem_category_id)
		} else if (subsystemCategorySetData && subsystemCategorySetData.length > 0) {
			setSelectCategorySet(subsystemCategorySetData[0].id)
		}
	}, [caseStudyData, subsystemCategorySetData])

	// 選択可能なカテゴリセット一覧
	const [selectCategorySetList, setSelectCategorySetList] = useState<Array<number>>(caseStudyData?.subsystem_category_set_ids || []);
	useEffect(() => {
		setSelectCategorySetList(caseStudyData?.subsystem_category_set_ids || []);
	}, [caseStudyData])

	// カテゴリセット初期表示
	// useEffect(() => {
	// 	if (caseStudyData?.subsystem_category_set_ids && subsystemCategorySetData) {
	// 		const initialRows = caseStudyData.subsystem_category_set_ids.map(categoryId => {
	// 			const categorySet = subsystemCategorySetData.find(set => set.id === categoryId);
	// 			return {
	// 				id: Date.now(), // 一意なIDを生成
	// 				inputCategoryId: categoryId,
	// 				inputCategoryName: categorySet?.name || '',
	// 				selectedCategoryValue: categorySet?.subsystem_categories.map(category => category.name).join(', ') || '',
	// 				selectedCategoryValueId: categoryId,
	// 				scope: categorySet?.scope || 'system'
	// 			};
	// 		});
	// 		setCategorySetRows(initialRows);
	// 	}
	// }, [caseStudyData, subsystemCategorySetData]);


	// カテゴリセット初期表示
	useEffect(() => {
		if (subsystemCategorySetData) {
			const uniqueRows = new Map<number, any>(); // ID をキーにして一意性を管理

			// subsystemCategorySetData のデータを追加
			subsystemCategorySetData.forEach(categorySet => {
				if (!uniqueRows.has(categorySet.id)) { // 既に追加されていない場合のみ追加
					uniqueRows.set(categorySet.id, {
						id: Date.now() + Math.random(), // 一意なIDを生成
						inputCategoryId: categorySet.id,
						inputCategoryName: categorySet.name || '',
						selectedCategoryValue: categorySet.subsystem_categories.map(category => category.name).join(', ') || '',
						selectedCategoryValueId: categorySet.id,
						scope: categorySet.scope || 'case_study'
					});
				}
			});

			// caseStudyData のデータを追加
			if (caseStudyData?.subsystem_category_set_ids) {
				caseStudyData.subsystem_category_set_ids.forEach(categoryId => {
					const categorySet = subsystemCategorySetData.find(set => set.id === categoryId);
					uniqueRows.set(categoryId, {
						id: Date.now() + Math.random(), // 一意なIDを生成
						inputCategoryId: categoryId,
						inputCategoryName: categorySet?.name || '',
						selectedCategoryValue: categorySet?.subsystem_categories.map(category => category.name).join(', ') || '',
						selectedCategoryValueId: categoryId,
						scope: categorySet?.scope || 'system'
					});
				});
			}

			// Map の値を配列に変換してセット
			setCategorySetRows(Array.from(uniqueRows.values()));
		}
	}, [caseStudyData, subsystemCategorySetData]);



	// レビューレベル
	const { reviewLevelTypeData, getReviewLevelType } = useGetReviewLevelType(); //レビュータイプの一覧
	const [selectReviewLevelData, setSelectReviewLevelData] = useState<any[] | undefined>(); // 選択されたレビュータイプ（更新用）
	const [reviewLevelsDataList, setReviewLevelsDataList] = useState<any[] | undefined>(); // テーブルでの表示用 
	useEffect(() => {
		if (!reviewLevelTypeData) {
			getReviewLevelType();
		}
	}, [caseStudyData])


	// パラメーター
	const [caseStudyParameters, setCaseStudyParameters] = useState<CaseStudyParametersrameters[] | undefined>();
	const [updateCaseStudyParameters, setUpdateCaseStudyParameters] = useState<CaseStudyParametersrameters[] | undefined>();
	useEffect(() => {
		setCaseStudyParameters(caseStudyData?.case_study_parameters);

	}, [caseStudyData])

	useEffect(() => {
		setUpdateCaseStudyParameters(caseStudyParameters?.filter(
			(param) => param.hasOwnProperty('name') && param.hasOwnProperty('value')
		));
	}, [caseStudyParameters])

	// parametersの加工
	const filteredCaseStudyParameters = updateCaseStudyParameters?.filter(
		(param) => param.hasOwnProperty('name') && param.hasOwnProperty('value'))
		.map((param) => ({
			name: param.name,
			value: param.value,
			locale:caseStudyData?.locale,
			description: param.description,
		}));

	// 製品システムステート
	const initialData = {
		product_system_diagram: caseStudyData?.product_system_diagram,
		subsystems: caseStudyData?.subsystems || [],
	}

	// 初期データによるNode
	const initialNodes: Node[] = initialData.subsystems.map((subsystem) => {
		const element = initialData.product_system_diagram?.elements.find(
			(el: any) => el.subsystem_no === subsystem.subsystem_no && el.type === 'subsystem'
		);
		return {
			id: subsystem.id.toString(),
			position: { x: element?.x || 0, y: element?.y || 0 },
			data: { ...subsystem },
			type: 'SUBSYSTEM', // カスタムノードタイプを設定
			width: 212,
			height: 165,
		};
	});

	type Element = SubsystemElement | ArrowElement;
	const isArrowElement = (element: Element): element is ArrowElement => element.type === 'arrow';

	// 初期データによるEdge
	const initialEdges: Edge<any>[] = (initialData.product_system_diagram?.elements
		.filter(isArrowElement)
		.map((el) => {
			// `upper_io_no` と一致する `process_io_no` を持つ subsystem を見つける
			const sourceSubsystem = initialData.subsystems.find((subsystem) =>
				subsystem.subsystem_ios.some((io) => io.process_io_no === el.upper_io_no)
			);
			const targetSubsystem = initialData.subsystems.find((subsystem) =>
				subsystem.subsystem_ios.some((io) => io.process_io_no === el.lower_io_no)
			);

			// `source` に一致する subsystem の `id` を設定
			const sourceId = sourceSubsystem ? sourceSubsystem.id.toString() : '';
			const targetId = targetSubsystem ? targetSubsystem.id.toString() : '';

			return {
				id: `e${sourceId}-${targetId}-${el.upper_io_no}-${el.lower_io_no}`,
				source: String(sourceId),
				sourceHandle: `output-${el.upper_io_no?.toString() || ''}`,
				target: String(targetId),
				targetHandle: `input-${el.lower_io_no?.toString()}`,
				style: { stroke: '#2b2b2b', strokeWidth: 2 },
				markerEnd: {
					type: MarkerType.ArrowClosed,
					color: '#2b2b2b',
				},
			};
		}) || []) as Edge<any>[];

	const [nodes, setNodes] = useNodesState(initialNodes);
	const [edges, setEdges] = useEdgesState(initialEdges);

	const initialFlowState: FlowState = {
		nodes: initialNodes,
		edges: initialEdges,
	};

	const [flowState, setFlowState, { undo, canUndo, redo, canRedo }] = useUndoable<FlowState>(initialFlowState, { maxHistory: 50 } as Options);

	useEffect(() => {
		setNodes(initialNodes);
		setEdges(initialEdges);


		// setFlowStateでnodesとedgesをセット
		setFlowState({
			nodes: initialNodes,
			edges: initialEdges,
		});
	}, [caseStudyData]);

	// 計算日時
	const {calculatedDateData, calculatedDateLoading, getCalculatedDate} = useGetCaseStudyCalculatedDate()
	const getLciCalculatedDate = async (): Promise<void> => {
		try {
			if (caseStudyData) {
				getCalculatedDate(caseStudyData?.id);
			}
		} catch (error) {
			console.log(error);
		}
	}
	useEffect(() =>{
		if(caseStudyData) {
			getLciCalculatedDate();
		}
	},[caseStudyData])

	// インベントリ分析
	const [inputGroupingValue, setInputGroupingValue] = useState<string>("1");  // グルーピング
	const [inputDsplayFormatValue, setInputDisplayFormatValue] = useState<string>("1"); // 表示形式

	// サブシステムごと
	const { getLciResults, setLciResultsData, lciResultsData, lciResultsLoading, lciResultsError } = useGetCaseStudyLciResults();

	// 上流ごと
	const { getLciResultsUpper, setLciResultsUpperData, lciResultsUpperData, lciResultsUpperLoading, lciResultsUpperError } = useGetCaseStudyLciResultsUpper();

	// カテゴリごと
	const { getLciResultsCategory, setLciResultsCategoryData, lciResultsCategoryData, lciResultsCategoryLoading, lciResultsCategoryError } = useGetCaseStudyLciResultsCategory();

	// 計算からLCIAの結果を取得するまでのローディングを管理
	const [calculateAndGetResultsLoading, setCalculateAndGetResultsLoading] = useState<boolean>(false);

	//　取得済みのサブシステムカテゴリごとのLCIの結果リスト
	const [inventoryAnalysCategorySetList, setInventoryAnalysCategorySetList] = useState<Array<{id: number, CaseStudyCalculate: CaseStudyCalculate | null}>>([]);

	// 　ケーススタディ計算結果
	const getLciData = async (): Promise<void> => {
		try {
			if(!calculatedDateData) {
				getLciCalculatedDate();
			}

			if (inputGroupingValue === "1" && !lciResultsData) {
				getLciResults(caseStudyData?.id ?? 0);
			} else if (inputGroupingValue === "2" && subsystemCategorySetData && subsystemCategorySetData.length > 0) {
				// getLciResultsCategory(caseStudyData?.id ?? 0, subsystemCategorySetData[0].id);
				const exists = inventoryAnalysCategorySetList.some(item => item.id === selectCategorySet);
				if (!exists) {
					getLciResultsCategory(caseStudyData?.id ?? 0, selectCategorySet)
						.then((result) => {
							setInventoryAnalysCategorySetList(prevList => [
								...prevList,
								{
									id: selectCategorySet,
									CaseStudyCalculate: result || null
								}
							]);
						});
				}
			} else if (inputGroupingValue === "3" && !lciResultsUpperData) {
				getLciResultsUpper(caseStudyData?.id ?? 0);
			}
		} catch (error: any) {
			console.log(error);
		}
	}

	// ケーススタディ計算結果(初期化)
	const getInitLciData = async (): Promise<void> => {
		setLciResultsData(null)
		setLciResultsUpperData(null)
		setLciResultsCategoryData(null)
		setInventoryAnalysCategorySetList([])
		try {
			getLciCalculatedDate();

			if (inputGroupingValue === "1") {
				getLciResults(caseStudyData?.id ?? 0);
			} else if (inputGroupingValue === "2" && subsystemCategorySetData && subsystemCategorySetData.length > 0) {
				getLciResultsCategory(caseStudyData?.id ?? 0, subsystemCategorySetData[0].id);
			} else if (inputGroupingValue === "3") {
				getLciResultsUpper(caseStudyData?.id ?? 0);
			}
		} catch (error: any) {
			console.log(error);
		}
	}

	// 初期表示用の計算結果取得
	// useEffect(() => {
	// 	if (caseStudyData && inputGroupingValue && calculatedDateData) {
	// 		getInitLciData();
	// 		setInitialDisplayFlag(true)
	// 	}
	// }, [calculatedDateData]);


	// グルーピングの変更
	useEffect(() => {
		if (calculatedDateData && caseStudyData && inputGroupingValue) {
			getLciData();
		}
	}, [inputGroupingValue]);

	// サブシステムカテゴリの変更
	useEffect(() => {
		if (caseStudyData && inputGroupingValue === "2") {
			getLciData();
		}
	}, [selectCategorySet]);


	// ケーススタディ計算結果
	const [inventoryAnalysData, setInventoryAnalysData] = useState<CaseStudyCalculate | null>(null);

	// テーブル表示用にセット
	useEffect(() => {
		// サブシステム
		if (inputGroupingValue === "1" && lciResultsData) {
			setInventoryAnalysData(lciResultsData);
		}

		// サブシステムカテゴリ
		if (inputGroupingValue === "2") {
			// selectCharacterizationCategorySetと一致するデータをcharacterizationCategorySetListから探す
			const matchedData = inventoryAnalysCategorySetList.find(item => item.id === selectCategorySet);
			if (matchedData && matchedData.CaseStudyCalculate) {
				console.log("matchedData", matchedData)
				setInventoryAnalysData(matchedData.CaseStudyCalculate);
			} else if (lciResultsCategoryData) {
				// 一致するデータがない場合は、caseStudyLciaResultsCategoryDataをセット
				setInventoryAnalysData(lciResultsCategoryData);
			}
		}

		// 上流フロー
		if (inputGroupingValue === "3" && lciResultsUpperData) {
			setInventoryAnalysData(lciResultsUpperData);
		}
	}, [lciResultsData, lciResultsUpperData, lciResultsCategoryData, inputGroupingValue, selectCategorySet]);


	// 特性化
	const [inputCharacterizationGroupingValue, setInputCharacterizationGroupingValue] = useState<string>("1");  // グルーピング
	const [inputCharacterizationDisplayFormatValue, setInputCharacterizationDisplayFormatValue] = useState<string>("1");// 表示形式

	// 特性化モデルの選択リスト
	const [selectCharacterizationModelIdList, setSelectCharacterizationModelIdList] = useState<Array<number>>([])
	// 特性化のサブシステムカテゴリ
	const [selectCharacterizationCategorySet, setSelectCharacterizationCategorySet] = useState<number>(0)
	
	//　取得済みのサブシステムカテゴリごとの特性化LCIAのリスト
	const [characterizationCategorySetList, setCharacterizationCategorySetList] = useState<Array<{id: number, CaseStudyLCIAResults: CaseStudyLCIAResults | null}>>([]);

	// 特性化結果取得
	const { excuteCaseStudyLciaResults, caseStudyLciaResultsData, setCaseStudyLciaResultsData, caseStudyLciaResultsLoading } = useCaseStudyLciaResults();
	const { excuteCaseStudyLciaResultsCategory, caseStudyLciaResultsCategoryData, setCaseStudyLciaResultsCategoryData, caseStudyLciaResultsCategoryLoading } = useCaseStudyLciaResultsCategory();
	const { excuteCaseStudyLciaResultsUpper, caseStudyLciaResultsUpperData, setCaseStudyLciaResultsUpperData, caseStudyLciaResultsUpperLoading } = useCaseStudyLciaResultsUpper();

	// 特性化モデル選択時
	useEffect(() => {
		// モデル変更時はリセット
		setCaseStudyLciaResultsData(null);
		setCaseStudyLciaResultsCategoryData(null);
		setCaseStudyLciaResultsUpperData(null);
		setCharacterizationResultData(null);
		setCharacterizationCategorySetList([]);

		if (lciResultsData && selectCharacterizationModelIdList && selectCharacterizationModelIdList.length > 0) {
			try {
				if (inputCharacterizationGroupingValue === "1") {
					excuteCaseStudyLciaResults(caseStudyData?.id || 0, selectCharacterizationModelIdList);
				}
				if (inputCharacterizationGroupingValue === "2") {
					// excuteCaseStudyLciaResultsCategory(caseStudyData?.id || 0, selectCharacterizationCategorySet, selectCharacterizationModelIdList);
					excuteCaseStudyLciaResultsCategory(caseStudyData?.id || 0, selectCharacterizationCategorySet, selectCharacterizationModelIdList)
                    .then((result ) => {
                        // 条件を満たす場合にCharacterizationCategorySetListに追加
                        setCharacterizationCategorySetList(prevList => [
                            ...prevList,
                            {
                                id: selectCharacterizationCategorySet,
                                CaseStudyLCIAResults: result  || null
                            }
                        ]);
                    });
				}
				if (inputCharacterizationGroupingValue === "3") {
					excuteCaseStudyLciaResultsUpper(caseStudyData?.id || 0, selectCharacterizationModelIdList);
				}
			} catch (error) {
				console.error('LCIA Results Error:', error);
			} finally {
				setCalculateAndGetResultsLoading(false)
			}
		}
	}, [selectCharacterizationModelIdList])

	// 計算実行時
	useEffect(() => {
		// 再計算時にもリセット
		setCaseStudyLciaResultsData(null);
		setCaseStudyLciaResultsCategoryData(null);
		setCaseStudyLciaResultsUpperData(null);
		setCharacterizationResultData(null);
		setCharacterizationCategorySetList([]);

		if (lciResultsData && selectCharacterizationModelIdList && selectCharacterizationModelIdList.length > 0) {
			try {
				if (inputCharacterizationGroupingValue === "1") {
					excuteCaseStudyLciaResults(caseStudyData?.id || 0, selectCharacterizationModelIdList);
				}
				if (inputCharacterizationGroupingValue === "2") {
					excuteCaseStudyLciaResultsCategory(caseStudyData?.id || 0, selectCharacterizationCategorySet, selectCharacterizationModelIdList);
				}
				if (inputCharacterizationGroupingValue === "3") {
					excuteCaseStudyLciaResultsUpper(caseStudyData?.id || 0, selectCharacterizationModelIdList);
				}
			} catch (error) {
				console.error('LCIA Results Error:', error);
			} finally {
				setCalculateAndGetResultsLoading(false)
			}
		}
	}, [lciResultsData])

	// 特性化グループ選択
	useEffect(() => {
		if (lciResultsData && selectCharacterizationModelIdList && selectCharacterizationModelIdList.length > 0) {
			try {
				if (inputCharacterizationGroupingValue === "1" && !caseStudyLciaResultsData) {
					excuteCaseStudyLciaResults(caseStudyData?.id || 0, selectCharacterizationModelIdList);
				}

				if (inputCharacterizationGroupingValue === "2") {
					// excuteCaseStudyLciaResultsCategory(caseStudyData?.id || 0, selectCharacterizationCategorySet, selectCharacterizationModelIdList);
					// すでに取得してる場合は実行しない
					const exists = characterizationCategorySetList.some(item => item.id === selectCharacterizationCategorySet);
					if (!exists) {
						excuteCaseStudyLciaResultsCategory(caseStudyData?.id || 0, selectCharacterizationCategorySet, selectCharacterizationModelIdList)
							.then((result) => {
								setCharacterizationCategorySetList(prevList => [
									...prevList,
									{
										id: selectCharacterizationCategorySet,
										CaseStudyLCIAResults: result  || null
									}
								]);
							});
					}
				} 

				if (inputCharacterizationGroupingValue === "3" && !caseStudyLciaResultsUpperData) {
					excuteCaseStudyLciaResultsUpper(caseStudyData?.id || 0, selectCharacterizationModelIdList);
				}
			} catch (error) {
				console.error('LCIA Results Error:', error);
			} finally {
				setCalculateAndGetResultsLoading(false)
			}
		}
	}, [inputCharacterizationGroupingValue])

	// 特性化カテゴリセット選択
	useEffect(() => {
		if (lciResultsData && selectCharacterizationModelIdList && selectCharacterizationModelIdList.length > 0) {
			try {
				if (inputCharacterizationGroupingValue === "2") {
					// excuteCaseStudyLciaResultsCategory(caseStudyData?.id || 0, selectCharacterizationCategorySet, selectCharacterizationModelIdList);
					const exists = characterizationCategorySetList.some(item => item.id === selectCharacterizationCategorySet);
					if (!exists) {
						excuteCaseStudyLciaResultsCategory(caseStudyData?.id || 0, selectCharacterizationCategorySet, selectCharacterizationModelIdList)
							.then((result ) => {
								setCharacterizationCategorySetList(prevList => [
									...prevList,
									{
										id: selectCharacterizationCategorySet,
										CaseStudyLCIAResults: result  || null
									}
								]);
							});
					}
				}
			} catch (error) {
				console.error('LCIA Results Error:', error);
			} finally {
				setCalculateAndGetResultsLoading(false)
			}
		}
	}, [selectCharacterizationCategorySet])


	// 特性化サブシステムカテゴリ
	useEffect(() => {
		if (subsystemCategorySetData && subsystemCategorySetData.length > 0) {
			setSelectCharacterizationCategorySet(subsystemCategorySetData[0].id)
		}
	}, [subsystemCategorySetData])


	// 特性化LCIAモデル
	const [midpointData, setMidpointData] = useState<ImpactAssesmentMethods[] | null>(null);
	useEffect(() => {
		const selectedModelIds = midpointData?.filter((method: ImpactAssesmentMethods) => method.is_default_selected)
			.map((method: ImpactAssesmentMethods) => method.id);

		setSelectCharacterizationModelIdList(selectedModelIds || []);
	}, [midpointData])

	const {
		getLciaModelsForMidpoint,
		lciaModelsForMidpointData,
	} = useGetLciaModelsForMidpoint();
	useEffect(() => {
		if (caseStudyData && !lciaModelsForMidpointData) {
			try {
				getLciaModelsForMidpoint(caseStudyData?.database_pack.id ?? 1);
			} catch (error) {
				console.log(error);
			}
		}
	}, [caseStudyData]);

	useEffect(() => {
		setMidpointData(lciaModelsForMidpointData);
	}, [lciaModelsForMidpointData]);


	// 特性化テーブル表示用のデータ
	const [characterizationResultData, setCharacterizationResultData] = useState<CaseStudyLCIAResults | null>(null);
	useEffect(() => {
        // サブシステムごと
        if (inputCharacterizationGroupingValue === "1" && caseStudyLciaResultsData) {
            setCharacterizationResultData(caseStudyLciaResultsData);
        }
	
        // カテゴリごと
		if (inputCharacterizationGroupingValue === "2") {
			// selectCharacterizationCategorySetと一致するデータをcharacterizationCategorySetListから探す
			const matchedData = characterizationCategorySetList.find(item => item.id === selectCharacterizationCategorySet);
			if (matchedData && matchedData.CaseStudyLCIAResults) {
				setCharacterizationResultData(matchedData.CaseStudyLCIAResults);
			} else if (caseStudyLciaResultsCategoryData) {
				// 一致するデータがない場合は、caseStudyLciaResultsCategoryDataをセット
				setCharacterizationResultData(caseStudyLciaResultsCategoryData);
			}
		}

        // 上流ごと
        if (inputCharacterizationGroupingValue === "3" && caseStudyLciaResultsUpperData) {
            setCharacterizationResultData(caseStudyLciaResultsUpperData);
        }
    }, [inputCharacterizationGroupingValue,　selectCharacterizationCategorySet,  caseStudyLciaResultsData, caseStudyLciaResultsCategoryData, caseStudyLciaResultsUpperData]);



	// 統合化
	const [inputDamageAssessmentGroupingValue, setInputDamageAssessmentGroupingValue] = useState<string>("1");  // グルーピング
	const [inputDamageAssessmentDisplayFormatValue, setInputDamageAssessmentDisplayFormatValue] = useState<string>("1");// 表示形式
	
	const [integrationData, setIntegrationData] = useState<ImpactAssesmentMethods[] | null>(null);
	const {
		getLciaModelsForIntegration,
		lciaModelsForIntegrationData,
	} = useGetLciaModelsForIntegration();
	useEffect(() => {
		if (caseStudyData && !integrationData) {
			try {
				getLciaModelsForIntegration(caseStudyData.database_pack.id);
			} catch (error) {
				console.log(error);
			}
		}
	}, [caseStudyData]);
	useEffect(() => {
		setIntegrationData(lciaModelsForIntegrationData);
	}, [lciaModelsForIntegrationData]);


	// 被害評価
	const [inputIntegrationGroupingValue, setInputIntegrationGroupingValue] = useState<string>("1");  // グルーピング
	const [inputIntegrationDisplayFormatValue, setInputIntegrationDisplayFormatValue] = useState<string>("1");// 表示形式

	const [endpointData, setEndpointData] = useState<ImpactAssesmentMethods[] | null>(null);
	const {
		getLciaModelsForEndpoint,
		lciaModelsForEndpointData,
	} = useGetLciaModelsForEndpoint();
	useEffect(() => {
		if (caseStudyData && !endpointData) {
			try {
				getLciaModelsForEndpoint(caseStudyData?.database_pack.id ?? 1);
			} catch (error) {
				console.log(error);
			}
		}
	}, [caseStudyData]);
	useEffect(() => {
		setEndpointData(lciaModelsForEndpointData);
	}, [lciaModelsForEndpointData]);


	// NodesとEdgesをProductSystemDiagramの形に加工
	const convertToProductSystemDiagram = (flowState: FlowState): ProductSystemDiagram => {
		const productSystemDiagram: ProductSystemDiagram = {
			selected_scale: 100,
			selected_subsystem_category_id: selectCategorySet,
			elements: []
		};

		// subsystem
		flowState.nodes.forEach(node => {
			productSystemDiagram.elements.push({
				x: node.position.x,
				y: node.position.y,
				z: 0,
				type: 'subsystem',
				subsystem_no: node.data.subsystem_no
			});
		});

		// Arrows
		flowState.edges.forEach(edge => {
			const sourceNode = flowState.nodes.find(node => String(node.id) === String(edge.source));
			const targetNode = flowState.nodes.find(node => String(node.id) === String(edge.target));

			if (sourceNode && targetNode) {
				const upper_io_no = sourceNode.data.subsystem_ios.find((io: any) => io.direction === 'out' && extractNumberFromHandle(edge.sourceHandle || "") === io.process_io_no)?.process_io_no;
				const lower_io_no = targetNode.data.subsystem_ios.find((io: any) => io.direction === 'in' && extractNumberFromHandle(edge.targetHandle || "") === io.process_io_no)?.process_io_no;

				if (lower_io_no !== undefined && upper_io_no !== undefined) {
					productSystemDiagram.elements.push({
						x: sourceNode.position.x,
						y: sourceNode.position.y,
						z: 0,
						type: 'arrow',
						subsystem_no: sourceNode.data.subsystem_no,
						lower_io_no: lower_io_no || null,
						upper_io_no: upper_io_no || null
					});
				}
			}
		});

		return productSystemDiagram;
	};

	// Nodesからケーススタディ専用の製品を抽出
	const convertToCaseStudyProducts = (
		flowState: FlowState,
		caseStudyData: GetCaseStudy | null
	): SubsystemIOCaseStudyProductOutExchange[] => {
		const caseStudyProducts: SubsystemIOCaseStudyProductOutExchange[] = [];

		flowState.nodes.forEach((node) => {
			// 'case_study_product' のすべての 'subsystem_ios' を抽出
			const filteredCaseStudyProducts = node.data.subsystem_ios.filter(
				(io: SubsystemIOCaseStudyProductOut) => io.type === 'case_study_product'
			);

			// 抽出されたすべての製品を caseStudyProducts に追加
			filteredCaseStudyProducts.forEach((caseStudyProduct: SubsystemIOCaseStudyProductOut) => {
				// case_study_product_no が一致する場合、caseStudyData.case_study_products を利用
				const matchingCaseStudyProduct = caseStudyData?.case_study_products.find(
					(product) => product.case_study_product_no === caseStudyProduct.exchange.case_study_product_no
				);

				// 既に同じ case_study_product_no の製品が存在しない場合のみ追加
				const existingProduct = caseStudyProducts.find(
					(product) => product.case_study_product_no === caseStudyProduct.exchange.case_study_product_no
				);

				if (!existingProduct) {
					caseStudyProducts.push({
						locale: matchingCaseStudyProduct?.locale || caseStudyProduct.locale,
						name: matchingCaseStudyProduct?.name || caseStudyProduct.exchange.name,
						synonyms_text: matchingCaseStudyProduct?.synonyms_text || caseStudyProduct.exchange.synonyms_text,
						include_items_text: matchingCaseStudyProduct?.include_items_text || caseStudyProduct.exchange.include_items_text,
						related_items_text: matchingCaseStudyProduct?.related_items_text || caseStudyProduct.exchange.related_items_text,
						public_comment: matchingCaseStudyProduct?.public_comment || caseStudyProduct.exchange.public_comment,
						private_comment: matchingCaseStudyProduct?.private_comment || caseStudyProduct.exchange.private_comment,
						id: matchingCaseStudyProduct?.id || caseStudyProduct.id || null,
						unit_id: matchingCaseStudyProduct?.unit_id || caseStudyProduct.unit.id,
						case_study_product_no: caseStudyProduct.exchange.case_study_product_no,
					});
				}
			});
		});

		return caseStudyProducts;
	};


	// 型ガードの定義
	function isDefinedProductOut(io: SubsystemIODefinedProductOut | SubsystemIOElementaryFlowOut | SubsystemIOCaseStudyProductOut): io is SubsystemIODefinedProductOut {
		return io.type === "defined_product";
	}

	function isElementaryFlowOut(io: SubsystemIODefinedProductOut | SubsystemIOElementaryFlowOut | SubsystemIOCaseStudyProductOut): io is SubsystemIOElementaryFlowOut {
		return io.type === "elementary_flow";
	}

	function isCaseStudyProductOut(io: SubsystemIODefinedProductOut | SubsystemIOElementaryFlowOut | SubsystemIOCaseStudyProductOut): io is SubsystemIOCaseStudyProductOut {
		return io.type === "case_study_product";
	}

	// 更新用のサブシステムに変換
	const transformSubsystems = (
		flowState: FlowState,
		initialData: { subsystems: any[]; product_system_diagram: any }
	): UpdateSubsystems[] => {
		return flowState.nodes.map((subsystem): UpdateSubsystems => {
			// subsystem.data.idがinitialData.subsystemsに存在するか確認  
			const matchingSubsystem = initialData.subsystems.find(
				(initialSubsystem) => initialSubsystem.id === subsystem.data.id
			);

			const subsystemIOS = subsystem.data.subsystem_ios.map((io: SubsystemIODefinedProductOut | SubsystemIOElementaryFlowOut | SubsystemIOCaseStudyProductOut) => {
				if (isDefinedProductOut(io)) {
					return {
						locale: io.locale,
						compatible_product_name: io.compatible_product_name || null,
						public_comment: io.public_comment || null,
						private_comment: io.private_comment || null,
						information_sources: io.information_sources || null,
						amount_comment: io.amount_comment || null,
						process_io_no: io.process_io_no,
						formula: io.formula || null,
						amount: io.amount,
						direction: io.direction,
						compatibility_level: io.compatibility_level,
						// id: io.id || null,
						id: null,
						unit_id: io.unit?.id || 1,
						type: io.type,
						exchange_id: io.exchange?.id || 1,
						// upper_process_io_id: io.upper_output?.id || null, // 後で除外？
						upper_process_output_id: io.upper_output?.id || null,
					} as SubsystemIODefinedProductIn;
				} else if (isElementaryFlowOut(io)) {
					return {
						locale: io.locale,
						compatible_product_name: io.compatible_product_name || null,
						public_comment: io.public_comment || null,
						private_comment: io.private_comment || null,
						information_sources: io.information_sources || null,
						amount_comment: io.amount_comment || null,
						process_io_no: io.process_io_no,
						formula: io.formula || null,
						amount: io.amount,
						direction: io.direction,
						compatibility_level: io.compatibility_level,
						id: io.id || null,
						unit_id: io.unit?.id || io.exchange?.unit.id || 1,
						type: io.type,
						exchange_id: io.exchange?.id || 1,
					} as SubsystemIOElementaryFlowIn;
				} else if (isCaseStudyProductOut(io)) {
					return {
						locale: io.locale,
						compatible_product_name: io.compatible_product_name || null,
						public_comment: io.public_comment || null,
						private_comment: io.private_comment || null,
						information_sources: io.information_sources || null,
						amount_comment: io.amount_comment || null,
						process_io_no: io.process_io_no,
						formula: io.formula || null,
						amount: io.amount,
						direction: io.direction,
						compatibility_level: io.compatibility_level,
						id: io.id || null,
						unit_id: io.unit?.id || 1,
						type: io.type,
						case_study_product_no: io.exchange.case_study_product_no,
						upper_subsystem_io_no: io.upper_subsystem_output_no || null,
					} as SubsystemIOCaseStudyProductIn;
				}
				return null;
			}).filter((io: any) => io !== null) as Array<SubsystemIODefinedProductIn | SubsystemIOElementaryFlowIn | SubsystemIOCaseStudyProductIn>;

			return {
				locale: subsystem.data.locale,
				name: subsystem.data.name || "",
				sampling_procedure: subsystem.data.sampling_procedure || null,
				criteria_for_excluding_intermediate_flow: subsystem.data.criteria_for_excluding_intermediate_flow || null,
				public_comment: subsystem.data.public_comment || null,
				private_comment: subsystem.data.private_comment || null,
				id: matchingSubsystem ? parseInt(subsystem.data.id, 10) : null,
				subsystem_no: subsystem.data.subsystem_no,
				subsystem_category_ids: subsystem.data.subsystem_category_ids.length > 0 ? subsystem.data.subsystem_category_ids : [1],
				subsystem_ios: subsystemIOS,
			};
		});
	};


	// 報告・解釈
	const { getAttachedFileBase64Image } = useGetAttachedFileBase64Image();
	// 画像
	const [previewImage, setPreviewImage] = useState<string | null>(null);
	const [imageSrc, setImageSrc] = useState<string | null>(null);
	const [imageName, setImageName] = useState<string | null>(null);
	const [imageFile, setImageFile] = useState<File>();

	useEffect(() => {
		const fetchImage = async () => {
			try {
				if (caseStudyData?.case_study_attached_files && caseStudyData.case_study_attached_files.length > 0) {
					const ImageFile = caseStudyData.case_study_attached_files.filter(file => file.is_product_system_summary === true)[0];
					if (ImageFile) {
						const base64Data = await getAttachedFileBase64Image(ImageFile.id || 0);
						setImageSrc(base64Data?.base64_data);
						setImageName(base64Data.filename);
					}
				}
			} catch (error) {
				console.error(error);
			}
		};
		fetchImage();
	}, [caseStudyData]);

	// システム境界画像
	const [previewSystemBoundaryImage, setPreviewSystemBoundaryImage] = useState<string | null>(null);
	const [systemBoundaryimageSrc, setSystemBoundaryImageSrc] = useState<string | null>(null);
	const [systemBoundaryimageName, setSystemBoundaryImageName] = useState<string | null>(null);
	const [systemBoundaryimageFile, setSystemBoundaryImageFile] = useState<File>();

	useEffect(() => {
		const fetchImage = async () => {
			try {
				if (caseStudyData?.case_study_attached_files && caseStudyData.case_study_attached_files.length > 0) {
					const ImageFile = caseStudyData.case_study_attached_files.filter(file => file.is_system_boundary === true)[0];
					if (ImageFile) {
						const base64Data = await getAttachedFileBase64Image(ImageFile.id || 0);
						setSystemBoundaryImageSrc(base64Data?.base64_data);
						setSystemBoundaryImageName(base64Data.filename);
					}
				}
			} catch (error) {
				console.error(error);
			}
		};
		fetchImage();
	}, [caseStudyData]);


	const { fileUpload, fileUploadData } = useFileUpload();

	// 目的の設定: 比較主張の有無
	const [hasComparisonClaim, setHasComparisonClaim] = useState<boolean | null>(false);
	useEffect(() => {
		setHasComparisonClaim(caseStudyData?.has_comparison_claim || false)
	}, [caseStudyData])

	// 保存処理
	const { updateCaseStudy, updateCaseStudyData, updateCaseStudyLoading } = useUpdateCaseStudy();

	// ボタン連打制御
	const [isDisabled, setIsDisabled] = useState(false);
	useEffect(() => {
		if (updateCaseStudyLoading) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
	}, [updateCaseStudyLoading])

	// アラート表示のフラグをuseStateで管理
	const [alertSavedEnabled, setSavedAlertEnabled] = useState<boolean>(false);


	// 更新parameterを作成する
	const createCaseStudyInUpdate = async(data: UpdateCaseStudy) =>{
		let Files: FileItem[] = [];

		// 初期データ
		if (caseStudyData?.case_study_attached_files && caseStudyData.case_study_attached_files.length > 0) {
			// setImageSrc が存在する場合
			if (imageSrc) {
				const productSystemSummaryFile = caseStudyData.case_study_attached_files.filter(file => file.is_product_system_summary === true)[0];
				if (productSystemSummaryFile) {
					// Files に product_system_summary のファイルをセット
					Files.push({
						id: productSystemSummaryFile.id,
						is_product_system_summary: productSystemSummaryFile.is_product_system_summary,
						is_system_boundary: productSystemSummaryFile.is_system_boundary
					});
				}
			}

			// systemBoundaryimageSrc が存在する場合
			if (systemBoundaryimageSrc) {
				const systemBoundaryFile = caseStudyData.case_study_attached_files.filter(file => file.is_system_boundary === true)[0];
				if (systemBoundaryFile) {
					// Files に system_boundary のファイルをセット
					Files.push({
						id: systemBoundaryFile.id,
						is_product_system_summary: systemBoundaryFile.is_product_system_summary,
						is_system_boundary: systemBoundaryFile.is_system_boundary
					});
				}
			}
		}

		// 画像新規追加
		if (imageFile) {
			// is_product_system_summary: true,のデータをFilesから除外
			Files = Files.filter(file => !file.is_product_system_summary);

			const response = await fileUpload(imageFile);
			Files.push({ id: response.id, is_product_system_summary: true, is_system_boundary: false });
		}

		// システム境界新規追加
		if (systemBoundaryimageFile) {
			// is_system_boundary: true,のデータをFilesから除外
			Files = Files.filter(file => !file.is_system_boundary);

			const response = await fileUpload(systemBoundaryimageFile);
			Files.push({ id: response.id, is_product_system_summary: false, is_system_boundary: true });
		}

		return {
			locale: caseStudyData?.locale,
			name: newCaseStudyName ?? data?.name ?? caseStudyData?.name,
			tags: data?.tags ?? caseStudyData?.tags,
			conductor_name: data.conductor_name,
			summary: data.summary,
			goal: data.goal,
			functional_unit: data.functional_unit,
			public_comment: data?.public_comment ?? caseStudyData?.public_comment,
			private_comment: data?.private_comment ?? caseStudyData?.private_comment,
			version: data.version ?? "1",
			conduct_date_at: data.conduct_date_at ?? new Date().toISOString().split('T')[0],
			case_study_review_level_value_ids: selectReviewLevelData?.map(item => item.review_level_id),
			case_study_parameters: filteredCaseStudyParameters,
			subsystem_category_set_ids: selectCategorySetList ?? [],
			product_system_diagram: convertToProductSystemDiagram(flowState),
			// subsystems: flowState.nodes.map(node => node.data),
			subsystems: transformSubsystems(flowState, initialData),
			case_study_products: convertToCaseStudyProducts(flowState, caseStudyData),

			report_creator_name: data?.report_creator_name ?? caseStudyData?.report_creator_name,
			lca_conductor_name: data?.lca_conductor_name ?? caseStudyData?.lca_conductor_name,
			lca_requester_name: data?.lca_requester_name ?? caseStudyData?.lca_conductor_name,
			product_system_summary_description: data?.product_system_summary_description ?? caseStudyData?.lca_conductor_name,
			product_system_function: data?.product_system_function ?? caseStudyData?.lca_conductor_name,
			system_boundary: data?.system_boundary ?? caseStudyData?.lca_conductor_name,
			system_boundary_image_name: data?.system_boundary_image_name ?? caseStudyData?.lca_conductor_name,
			criteria_for_cutoff: data?.criteria_for_cutoff ?? caseStudyData?.lca_conductor_name,
			temporal_correlation: data?.temporal_correlation ?? caseStudyData?.lca_conductor_name,
			geographical_correlation: data?.geographical_correlation ?? caseStudyData?.lca_conductor_name,
			technological_correlation: data?.technological_correlation ?? caseStudyData?.lca_conductor_name,
			accuracy: data?.accuracy ?? caseStudyData?.lca_conductor_name,
			completeness: data?.completeness ?? caseStudyData?.lca_conductor_name,
			representativeness: data?.representativeness ?? caseStudyData?.lca_conductor_name,
			consistency: data?.consistency ?? caseStudyData?.lca_conductor_name,
			reproducibility: data?.reproducibility ?? caseStudyData?.lca_conductor_name,
			data_source: data?.data_source ?? caseStudyData?.lca_conductor_name,
			uncertainty: data?.uncertainty ?? caseStudyData?.lca_conductor_name,
			text_of_characterization_results: data?.text_of_characterization_results ?? caseStudyData?.lca_conductor_name,
			text_of_damage_assessment_results: data?.text_of_damage_assessment_results ?? caseStudyData?.lca_conductor_name,
			text_of_integration_results: data?.text_of_integration_results ?? caseStudyData?.lca_conductor_name,
			interpretation_of_characterization_results: data?.interpretation_of_characterization_results ?? caseStudyData?.lca_conductor_name,
			interpretation_of_damage_assessment_results: data?.interpretation_of_damage_assessment_results ?? caseStudyData?.lca_conductor_name,
			interpretation_of_integration_results: data?.interpretation_of_integration_results ?? caseStudyData?.lca_conductor_name,
			sensitivity_analysis: data?.sensitivity_analysis ?? caseStudyData?.lca_conductor_name,
			data_quality_evaluation: data?.data_quality_evaluation ?? caseStudyData?.lca_conductor_name,
			limit: data?.limit ?? caseStudyData?.lca_conductor_name,
			conclusion: data?.conclusion ?? caseStudyData?.lca_conductor_name,
			proposal: data?.proposal ?? caseStudyData?.lca_conductor_name,
			has_comparison_claim: hasComparisonClaim,
			review_by_user: data?.review_by_user ?? caseStudyData?.review_by_user ?? null,
			review_by_reviewer: data?.review_by_reviewer ?? caseStudyData?.review_by_reviewer ?? null,
			report_created_date_at:
				data?.report_created_date_at === ''
					? null
					: data?.report_created_date_at ?? caseStudyData?.report_created_date_at,
			attached_file_list: Files,
			system_boundary_description: data?.system_boundary_description ?? caseStudyData?.system_boundary_description,
		}
	}


	// 保存
	const onSubmit: SubmitHandler<UpdateCaseStudy> = async data => {
		if (isDisabled) return;

		setInitialDisplayFlag(true);
		openLoadingDaialog();

		// parameter生成
		const updateParam = await createCaseStudyInUpdate(data);

		if (updateCaseStudyParameters && updateCaseStudyParameters.length > 0) {
			// `name` または `value` のどちらかが空または未定義である場合に true を返す
			const hasEmptyFields = updateCaseStudyParameters.some(param =>
				!param.name || param.name === "" || param.value === null || param.value === undefined
			);

			if (hasEmptyFields) {
				setAlertDaialogMessage(t('パラメータ設定に空欄の項目があります'))
				openAlertDialog()
				if (!alertSavedEnabled) {
					throw new Error('パラメータ設定に空欄の項目があります');
				}
				closeLoadingDaialog(); // 処理が終わったら必ずダイアログを閉じる
				return;
			}

			// `name` が英数字でない場合に true を返す
			const hasInvalidNames = updateCaseStudyParameters.some(param =>
				!checkPrameterName(param.name)
			);

			if (hasInvalidNames) {
				setAlertDaialogMessage(t('パラメーター名は英数字で入力してください'))
				openAlertDialog()
				if (!alertSavedEnabled) {
					throw new Error('パラメーター名は英数字で入力してください');
				}
				closeLoadingDaialog(); // 処理が終わったら必ずダイアログを閉じる
				return;
			}
		}

		try {
			await updateCaseStudy(caseStudyData?.id, updateParam)
			if (alertSavedEnabled) {
				setAlertDaialogMessage(t('保存しました'))
				openAlertDialog()
			}
			await getCaseStudy(Number(params.id));
			setLciResultsData(null);
			setLciResultsCategoryData(null);
			setLciResultsUpperData(null);
			setInventoryAnalysData(null);
			setCaseStudyLciaResultsData(null);
			setCaseStudyLciaResultsCategoryData(null);
			setCaseStudyLciaResultsUpperData(null);
			setCharacterizationResultData(null);
			setInventoryAnalysCategorySetList([])
			setCharacterizationCategorySetList([])

			setChangeCheckSubsystem(false);
			setChangeCheckInfo(false);
			setchangeCheckReport(false);
			setChangeCheckReviewLevel(false);
			setchangeCheckParameter(false);
			setchangeCheckReport(false);

			setSystemBoundaryImageFile(undefined);
			setImageFile(undefined);

		} catch (error: any) {
			console.log("Error: ", error)
			if(error.response && error.response.data?.detail){
                setAlertDaialogMessage(<>{t('保存に失敗しました')} <br/> {error.response.data?.detail} </>)
            } else {
                setAlertDaialogMessage(<>{t('保存に失敗しました')} <br/> {t('申しわけありませんが、システム管理者までお問い合わせください')}</>)
            }
			if (!alertSavedEnabled) {
				if(error.response && error.response.data?.detail){
					throw new Error(`${t('保存に失敗しました')}\n ${error.response.data?.detail} `);
				} else {
					throw new Error(`${t('保存に失敗しました')}\n ${t('申しわけありませんが、システム管理者までお問い合わせください')} `);
				}
			}
			openAlertDialog()
		} finally {
			closeLoadingDaialog(); // 処理が終わったら必ずダイアログを閉じる
		}
	}

	// 別名のケーススタディで保存
	const {saveAsNewCaseStudy, saveAsNewCaseStudyData, saveAsNewCaseStudyLoading, saveAsNewCaseStudyError } = useSaveAsNewCaseStudy()
	const [saveAsNewCaseStudyAlertDialogOpen, setSaveAsNewCaseStudyAlertDialogOpen] = useState(false);
	const [newCaseStudyName, setNewCaseStudyName] = useState<string | null>(null)
	const handlesaveAsNewCaseStudyAlertDialogOpen = async() =>{
		setSaveAsNewCaseStudyAlertDialogOpen(true)
	}

	const handlesaveAsNewCaseStudyAlertDialogClose = async() =>{
		setNewCaseStudyName(null)
		setSaveAsNewCaseStudyAlertDialogOpen(false)
	}

	const onSaveAsNewSubmit: SubmitHandler<UpdateCaseStudy> = async data => {
		// parameter生成
		const updateParam = await createCaseStudyInUpdate(data);

		if (updateCaseStudyParameters && updateCaseStudyParameters.length > 0) {
			// `name` または `value` のどちらかが空または未定義である場合に true を返す
			const hasEmptyFields = updateCaseStudyParameters.some(param =>
				!param.name || param.name === "" || param.value === null || param.value === undefined
			);

			if (hasEmptyFields) {
				setAlertDaialogMessage(t('パラメータ設定に空欄の項目があります'))
				openAlertDialog()
				if (!alertSavedEnabled) {
					throw new Error('パラメータ設定に空欄の項目があります');
				}
				return;
			}

			// `name` が英数字でない場合に true を返す
			const hasInvalidNames = updateCaseStudyParameters.some(param =>
				!checkPrameterName(param.name)
			);

			if (hasInvalidNames) {
				setAlertDaialogMessage(t('パラメーター名は英数字で入力してください'))
				openAlertDialog()
				if (!alertSavedEnabled) {
					throw new Error('パラメーター名は英数字で入力してください');
				}
				return;
			}
		}

		try {
			const responce = await saveAsNewCaseStudy(caseStudyData?.id, updateParam)
			if (alertSavedEnabled) {
				setAlertDaialogMessage(t('保存しました'))
				openAlertDialog()
			}
			window.location.href = `/case_study/case_study_view/${responce.id}`

		} catch (error: any) {
			console.log("Error: ", error)
			setAlertDaialogMessage(t('保存に失敗しました'))
			openAlertDialog()
		} finally {
			setNewCaseStudyName(null)
		}
	}

	const handleUnitProcessConfirm = async () => {
		await handleSubmit(onSaveAsNewSubmit)();
		setSaveAsNewCaseStudyAlertDialogOpen(false);
	};

	const AlertDialogTitele = t("別のケーススタディとして保存")

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewCaseStudyName(event.target.value);
    }

	const AlertDialogMessage = (): JSX.Element => {
		return (
			<>
				{t('ケーススタディ名')}
				<InputPrimary 
					type='text'
					value={newCaseStudyName ?? ""}
					onChange={handleInputChange}
				/>
			</>
		);
	};

	// 変更を破棄
	const hundleUndo = async () => {
		const isConfirmed = window.confirm(t('変更を破棄しますか？'));
		if (isConfirmed) {
			try {
				window.location.reload();
			} catch (error) {
				console.log("Error: ", error)
			}
		}
	}

	// 変更監視
	const [changeCheckSubsystem, setChangeCheckSubsystem] = useState<boolean>(false);
	const [changeCheckInfo, setChangeCheckInfo] = useState<boolean>(false);
	const [changeCheckReviewLevel, setChangeCheckReviewLevel] = useState<boolean>(false);
	const [changeCheckProcessReviewLevel, setChangeCheckProcessReviewLevel] = useState<boolean>(false);
	const [changeCheckParameter, setchangeCheckParameter] = useState<boolean>(false);
	const [changeCheckReport, setchangeCheckReport] = useState<boolean>(false);

	// ページのリロードやブラウザバック時に警告
	const [isBlocking, setIsBlocking] = useState(false);

	useEffect(() => {
		if (changeCheckSubsystem ||
			changeCheckInfo ||
			changeCheckReviewLevel ||
			changeCheckProcessReviewLevel ||
			changeCheckParameter ||
			changeCheckReport
		) {
			setIsBlocking(true);
		} else {
			setIsBlocking(false);
		}
	}, [changeCheckSubsystem, changeCheckInfo, changeCheckReviewLevel, changeCheckProcessReviewLevel, changeCheckParameter, changeCheckReport])

	// ページリロードやブラウザバック時に警告
	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (isBlocking) {
				event.preventDefault();
				event.returnValue = ""; // 一部ブラウザで必要
			}
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};

	}, [isBlocking]); // isBlocking の変更を監視


	// 比較に不要なデータを除外
	const removeUnnecessaryProperties = (state: FlowState) => {
		return {
			...state,
			nodes: state.nodes.map((node) => {
				const { width, height, selected, positionAbsolute, ...rest } = node;
				return { ...rest, position: { ...node.position } };
			}),
			edges: state.edges.map(({ style, markerEnd, ...rest }) => rest),
		};
	};

	useEffect(() => {
		const initialFlowStateWithoutSize = removeUnnecessaryProperties(initialFlowState);
		const flowStateWithoutSize = removeUnnecessaryProperties(flowState);
		if (JSON.stringify(initialFlowStateWithoutSize) !== JSON.stringify(flowStateWithoutSize)) {
			setChangeCheckSubsystem(true);
		}
	}, [flowState])

	useEffect(() => {
		if (JSON.stringify(caseStudyParameters) !== JSON.stringify(updateCaseStudyParameters)) {
			setchangeCheckParameter(true);
		}
	}, [updateCaseStudyParameters])


	useEffect(() => {
		if (JSON.stringify(caseStudyData?.case_study_review_level_values) !== JSON.stringify(selectReviewLevelData)) {
			setChangeCheckReviewLevel(true);
		}
	}, [selectReviewLevelData])


	const { getUnitGroups, unitGroupsData, unitGroupsLoading, unitGroupsError } = useGetUnitGroups();
	useEffect(() => {
		if (!databasePacks) {
			return
		}
		try {
			getUnitGroups(databasePacks)
		} catch (error) {
			console.log(error);
		}
	}, [databasePacks]);


	const resetCaseStudyData = async () => {
		try {
			await getCaseStudy(Number(params.id))
		} catch (error) {
			console.log(error)
		}
	}

	// 単位換算
	const { getUnitConversions, unitConversionsData, unitConversionsError, unitConversionsLoading } = useGetUnitConversions();
	useEffect(() => {
		try {
			if (caseStudyData && (!unitConversionsData || unitConversionsData.length === 0)) {
				getUnitConversions(caseStudyData.database_pack.id);
			}
		} catch (error) {
			console.log(error);
		}
	}, [caseStudyData])


	// 保存ダイアログ
	const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
	const openAlertDialog = () => setIsAlertDialogOpen(true);
	const closeAlertDialog = () => setIsAlertDialogOpen(false);
	const [alertDaialogMessage, setAlertDaialogMessage] = useState<string | JSX.Element>("")

	// ローディングダイアログ
	const [isLoadingDaialogOpen, setIsLoadingDaialogOpen] = useState(false);
	const openLoadingDaialog = () => setIsLoadingDaialogOpen(true);
	const closeLoadingDaialog = () => setIsLoadingDaialogOpen(false);
	const [daialogMessage, setDaialogMessage] = useState<string>("");
	const daialogLoadingMessage = t('保存しています');

	return (
		<BaseArea>
			<CaseStudyViewContext.Provider value={{
				userData,
				unitGroupsData,
				caseStudyData,
				resetCaseStudyData,
				setUnsaved,
				databasePacks,
				nodes,
				edges,
				reviewLevelTypeData,
				selectReviewLevelData,
				reviewLevelsDataList,
				setSelectReviewLevelData,
				setReviewLevelsDataList,
				caseStudyParameters,
				updateCaseStudyParameters,
				setCaseStudyParameters,
				setUpdateCaseStudyParameters,
				setNodes,
				setEdges,
				subsystemCategorySetData,
				setSubsystemCategorySetData,
				flowState,
				setFlowState,
				undo,
				canUndo,
				redo,
				canRedo,
				midpointData,
				endpointData,
				integrationData,
				inputCharacterizationGroupingValue,
				setInputCharacterizationGroupingValue,
				inputCharacterizationDisplayFormatValue,
				setInputCharacterizationDisplayFormatValue,
				inputDsplayFormatValue,
				setInputDisplayFormatValue,
				inputGroupingValue,
				setInputGroupingValue,
				selectCategorySet,
				setSelectCategorySet,
				selectCategorySetList,
				setSelectCategorySetList,
				categorySetRows,
				setCategorySetRows,
				previewImage,
				setPreviewImage,
				imageSrc,
				setImageSrc,
				imageName,
				setImageName,
				imageFile,
				setImageFile,
				previewSystemBoundaryImage,
				setPreviewSystemBoundaryImage,
				systemBoundaryimageSrc,
				setSystemBoundaryImageSrc,
				systemBoundaryimageName,
				setSystemBoundaryImageName,
				systemBoundaryimageFile,
				setSystemBoundaryImageFile,
				onSubmit,
				hasComparisonClaim,
				setHasComparisonClaim,
				changeCheckSubsystem,
				setChangeCheckSubsystem,
				changeCheckInfo,
				setChangeCheckInfo,
				changeCheckReviewLevel,
				setChangeCheckReviewLevel,
				changeCheckProcessReviewLevel,
				setChangeCheckProcessReviewLevel,
				changeCheckParameter,
				setchangeCheckParameter,
				changeCheckReport,
				setchangeCheckReport,
				selectCharacterizationModelIdList,
				setSelectCharacterizationModelIdList,
				selectCharacterizationCategorySet,
				setSelectCharacterizationCategorySet,
				caseStudyLciaResultsData,
				caseStudyLciaResultsCategoryData,
				caseStudyLciaResultsUpperData,
				caseStudyLciaResultsLoading,
				caseStudyLciaResultsCategoryLoading,
				caseStudyLciaResultsUpperLoading,
				lciResultsCategoryData,
				lciResultsLoading,
				lciResultsUpperData,
				lciResultsData,
				getInitLciData,
				setSavedAlertEnabled,
				lciResultsCategoryLoading,
				lciResultsUpperLoading,
				calculateAndGetResultsLoading,
				setCalculateAndGetResultsLoading,
				inputDamageAssessmentGroupingValue,
				setInputDamageAssessmentGroupingValue,
				inputDamageAssessmentDisplayFormatValue,
				setInputDamageAssessmentDisplayFormatValue,
				inputIntegrationGroupingValue,
				setInputIntegrationGroupingValue,
				inputIntegrationDisplayFormatValue,
				setInputIntegrationDisplayFormatValue,
				characterizationResultData,
				inventoryAnalysData,
				setInventoryAnalysData,
				calculatedDateData,
				calculatedDateLoading,
				getLciCalculatedDate,
				unitConversionsData
			}}>
				<MessageDaialog
					open={isAlertDialogOpen}
					onClose={closeAlertDialog}
					message={alertDaialogMessage}
				/>
        <LoadingDialog
          open={isLoadingDaialogOpen}
          onClose={closeLoadingDaialog}
          loading={updateCaseStudyLoading}
          loading_message={daialogLoadingMessage}
          result_message={daialogMessage}
        />

				{caseStudyError && <ErrorMsg>{caseStudyError}</ErrorMsg>}
				{caseStudyLoading &&
					<FadeLoader
						color="#48bdbb"
						height={10}
						radius={2}
						width={5}
					/>
				}
				{caseStudyData &&
					<form onSubmit={handleSubmit(onSubmit)}>
						<StyledHeading>
							<div>
								<StyledHeadingPText>{t('ケーススタディ名')}</StyledHeadingPText>
								<StyledHeadingTitle>
									{caseStudyData?.name}
								</StyledHeadingTitle>
							</div>
							<div>
								<StyledHeadingPText>{t('選択中のデータベースパック')}</StyledHeadingPText>
								<StyledHeadingTitle>
									{caseStudyData?.database_pack.name}
								</StyledHeadingTitle>
							</div>
							<StyledHeadingNav>
								<StyledHeadingNavList>

									<StyledHeadingNavListItem>
										<A onClick={handlesaveAsNewCaseStudyAlertDialogOpen}>{t('別のケーススタディとして保存')}</A>
										<AlertDialogDisable
											open={saveAsNewCaseStudyAlertDialogOpen}
											onClose={handlesaveAsNewCaseStudyAlertDialogClose}
											onConfirm={handleUnitProcessConfirm}
											title={AlertDialogTitele}
											message={AlertDialogMessage()}
											disableConfirmButton={saveAsNewCaseStudyLoading} // OKボタンの無効化をフラグで制御
										/>
									</StyledHeadingNavListItem>

									<StyledHeadingNavListItem>
										<StyledHeadingNavListItemButton type='button' onClick={() => { hundleUndo() }}>
											<StyledHeadingNavListItemButtonText>
												{t('変更を破棄')}
											</StyledHeadingNavListItemButtonText>
										</StyledHeadingNavListItemButton>
									</StyledHeadingNavListItem>

									<StyledHeadingNavListItem>
										<StyledHeadingNavListItemButton type='submit' disabled={isDisabled} onClick={() => { setSavedAlertEnabled(true) }}>
											{/* {unsaved && <span>＊</span>} */}
											<StyledHeadingNavListItemButtonText>
												{(changeCheckSubsystem || changeCheckParameter || changeCheckReviewLevel || changeCheckInfo || changeCheckReport) && <span>*</span>}
												{t('保存する')}
											</StyledHeadingNavListItemButtonText>
										</StyledHeadingNavListItemButton>
									</StyledHeadingNavListItem>

								</StyledHeadingNavList>
							</StyledHeadingNav>
						</StyledHeading>
						<div>
							{/* タブのボタン */}
							<StyledPageNavList>
								<StyledPageNavCurrent>
									<TabButton type='button' onClick={() => handleTabClick('tab1')} isActive={activeTab === 'tab1'}>
										<TabButtonSpan>{t('ケーススタディ情報')}</TabButtonSpan>
									</TabButton>
								</StyledPageNavCurrent>
								<StyledPageNavCurrent>
									<TabButton type='button' onClick={() => handleTabClick('tab2')} isActive={activeTab === 'tab2'}>
										<TabButtonSpan>{t('パラメーター')}</TabButtonSpan>
									</TabButton>
								</StyledPageNavCurrent>
								<StyledPageNavCurrent>
									<TabButton type='button' onClick={() => handleTabClick('tab3')} isActive={activeTab === 'tab3'}>
										<TabButtonSpan>{t('製品システム')}</TabButtonSpan>
									</TabButton>
								</StyledPageNavCurrent>
								<StyledPageNavCurrent>
									<TabButton type='button' onClick={() => handleTabClick('tab4')} isActive={activeTab === 'tab4'}>
										<TabButtonSpan>{t('計算')}</TabButtonSpan>
									</TabButton>
								</StyledPageNavCurrent>
								<StyledPageNavCurrent>
									<TabButton type='button' onClick={() => handleTabClick('tab5')} isActive={activeTab === 'tab5'}>
										<TabButtonSpan>{t('解釈・報告')}</TabButtonSpan>
									</TabButton>
								</StyledPageNavCurrent>
							</StyledPageNavList>

							{/* タブの内容 */}
							{activeTab === 'tab1' && <TabPanel><CaseStudyInfo watch={watch} control={control} register={register} errors={errors} /></TabPanel>}
							{activeTab === 'tab2' && <TabPanel><CaseStudyParameterSetting /></TabPanel>}
							{activeTab === 'tab3' && <TabPanel><ProductSystem /></TabPanel>}
							{activeTab === 'tab4' && <TabPanel><CaseStudyCalculation /></TabPanel>}
							{activeTab === 'tab5' && <TabPanel><Report watch={watch} control={control} register={register} errors={errors} /></TabPanel>}
						</div>
					</form>
				}
			</CaseStudyViewContext.Provider>
		</BaseArea>
	)
}

export default CaseStudyView

const TabButton = styled.button.withConfig({
	shouldForwardProp: (prop) => prop !== 'isActive'
}) <{ isActive: boolean }>`
  padding: 3px 13px;
  background-color: var(--color-gray--01);
  color: black;
  border: none;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 20px;

  ${(props) =>
		props.isActive &&
		css`
      background-color: var(--color-site-secondary);
      color: #fff !important;
    `
	}

  &:hover {
    background-color: var(--color-site-secondary);
    color: #fff !important;
    ${(props) =>
		props.isActive &&
		css`
      background-color: var(--color-site-secondary);
      `
	}
  }
`;

const TabPanel = styled.div`
    padding: 20px 0px 0px;
    // margin-top: 10px;
  `;

const TabButtonSpan = styled.span`
  font-family: "Noto Sans JP", sans-serif;
  font-size: 12px;
`

const StyledHeading = styled.div`
  width: calc(100% + 32px);
  margin-bottom: 40px;
  margin-left: -16px;
  padding: 24px 16px;
  background-color: #fff;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
  position: sticky;
  top: 90px;
  left: 0;
  z-index: 10;
`

const StyledHeadingPText = styled.p`
  color: var(--color-txt-tertiary);
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
`

const StyledHeadingTitle = styled.h2`
  color: var(--color-txt-secondary);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.25;
`

const StyledHeadingNav = styled.nav`
  margin-left: auto;  
`
const StyledHeadingNavList = styled.ul`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 16px;
`
const StyledHeadingNavListItem = styled.li`
  font-size: 13px;
  font-weight: 500;
`
const StyledHeadingNavListItemButton = styled.button`
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid var(--color-site-secondary);

  padding: 4px 16px;

  display: inline-flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;
  gap: 0 4px;
  &:hover {
    color: #fff;
    & > span {
      color: #fff;
    }
    background-color: var(--color-site-secondary);
  }
`
const StyledHeadingNavListItemButtonText = styled.span`
    font-size: 13px;
    font-weight: 500;
    color: var(--color-site-secondary);
    font-family: "Noto Sans JP", sans-serif;
`

const StyledPageNavList = styled.ul`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 12px;
`

const StyledPageNavCurrent = styled.li`
`

const InputPrimary = styled.input`
    width: min(100%, 340px);

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const InputBox = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;    
`

const InputWrap = styled.div`
    width: 100%;
    position: relative;
`
