import ProcessIoDataQualityValueTable from '@common/table/ProcessIoDataQualityValueTable';
import { useCcalculateMathFormula, useGetUnitGroups } from '@hooks/useBackendApi';
import { ProcessViewContext } from '@pages/process/ProcessView';
import { DataQualityIndicators, ProcessIoDataQualityValues, ProcessIosType, UnitGroupList, UpdateProcess } from '@typeList/types';
import React, { useContext, useEffect, useState } from 'react'
import FlowParameterSettingListModal from '../flow_parameter_setting/FlowParameterSettingListModal';
import styled from 'styled-components';
import ModalButton from '@common/button/ModalButton';
import { useTranslation } from 'react-i18next';
import { RequiredSpan } from '@styles/Common';
import { formatNumber } from '@utils/utils';
import ElementaryFlowSearchResourcesAndWasteEditModal from '../elementary_flow_search/elementary_flow_edit/ElementaryFlowSearchResourcesAndWasteEditModal';
import { checkFormula } from '@utils/utils';
import { useUnitHooks } from '@hooks/useUnitHook';
import { useForm } from 'react-hook-form';

interface InputElementaryFlowEditType {
    Id: number;
    onClose: () => void;
}

/**
 * 024　出力基本フロー（直接投排出物質）の編集
 * @returns 
 */
const OutputElementaryFlowEditModal: React.FC<InputElementaryFlowEditType> = ({ Id, onClose }) => {
    const { t } = useTranslation();
    const context = useContext(ProcessViewContext);

    const { 
        userData, 
        unitGroupsData, 
        setOutElementaryFlow, 
        outElementaryFlow, 
        unitConversionsData, 
        dataQualityIndicatorsData, 
        setChangeCheckFlow, 
        processData,
        onSubmit,
        updateProcessIos
    } = context;

    const data = outElementaryFlow && outElementaryFlow.length > 0 ? outElementaryFlow[Id] : null;
    const [inputElementaryFlowName, setInputElementaryFlowName] = useState<string>(data?.exchange.name ?? "");
    // const [inputAmount, setInputAmount] = useState<number>(data?.amount ?? 0);
    const [inputAmount, setInputAmount] = useState<number | string | null>(
        formatNumber(
            data?.amount || 0,
            userData?.significant_figures ?? 2,
            userData?.use_decimal_notation ?? false,
            userData?.decimal_places ?? 2
        ) ?? null // 初期値を null として管理
    );

    const [inputElementaryFlowId, setInputElementaryFlowId] = useState<number>(data?.exchange.id ?? 0)
    const [inputAmountFormula, setInputAmountFormula] = useState<string | undefined>(data?.formula ?? "");
    const [inputPublicComment, setInputPublicComment] = useState<string>(data?.public_comment ?? "");
    const [inputPrivateComment, setInputPrivateComment] = useState<string>(data?.private_comment ?? "");
    const [inputUnitId, setInputUnitId] = useState<number>(data?.unit.id ?? 0);
    const [inputUnitName, setInputUnitName] = useState<string>(data?.unit.name ?? "");
    const [inputUnitCode, setInputUnitCode] = useState<string>(data?.unit.code ?? "");
    const [inputUnitLocale, setInputUnitLocale] = useState<string>(data?.unit.locale ?? "");
    const [inputUnitPrivateComment, setInputUnitPrivateComment] = useState<string | null>(data?.unit.private_comment ?? "");
    const [inputUnitPublicComment, setInputUnitPublicComment] = useState<string | null>(data?.unit.public_comment ?? "");
    const [inputCompatibleProductName, setInputCompatibleProductName] = useState<string>(data?.compatible_product_name ?? "");
    const [inputAmountComment, setInputAmountComment] = useState<string>(data?.amount_comment ?? "");
    const [inputInformationSources, setInputInformationSources] = useState<string>(data?.information_sources ?? "");
    const [inputCompatibilityLevel, setInputCompatibilityLevel] = useState<number>(data?.compatibility_level ?? 1);

    // エラーチェック
    const [elementaryFlowNameError, setElementaryFlowNameError] = useState<any>(null);
    const [amountError, setAmountError] = useState<any>(null);
    const [amountFormulaError, setAmountFormulaError] = useState<any>(null);
    const validateCheck = (): boolean => {
        setElementaryFlowNameError(null);
        setAmountError(null);
        setAmountFormulaError(null);
        if (!inputElementaryFlowName) {
            setElementaryFlowNameError(t('製品名は必須です'));
        }
        if (inputAmount === null || inputAmount === undefined) {
            setAmountError(t('流量は必須です'));
        }
        if (!checkFormula(inputAmountFormula)) {
            setAmountFormulaError(t('数式に使えない文字が混ざっています。使える文字は、英数と._+-*/()です'));
        }
        if (!inputElementaryFlowName || inputAmount === null || inputAmount === undefined || !checkFormula(inputAmountFormula)) {
            console.log("validateCheck false")
            return false;
        }
        return true;
    }

    // 単位マスタから取得
    const [unitList, setUnitList] = useState<UnitGroupList[]>();

    // フローの単位が所属するグループでフィルター
    const getParentIdByUnitId = (unitGroups: UnitGroupList[], unitId: number): number | undefined => {
        for (const group of unitGroups) {
            for (const unit of group.units) {
                if (unit.id === unitId) {
                    return group.id;
                }
            }
        }
        return undefined;
    };
    useEffect(() => {
        if (unitGroupsData) {
            const list = unitGroupsData?.filter(units => units.id === getParentIdByUnitId(unitGroupsData, inputUnitId ?? 0))
            setUnitList(list)
        }
    }, [unitGroupsData, inputUnitId])
    
    const { applyUnitConversion } = useUnitHooks();

    // 分類
    const [categoryName, setCategoryName] = useState<string>("");
    useEffect(() => {
        setCategoryName(data?.exchange.category1_name + "/" + data?.exchange.category2_name + "/" + data?.exchange.category3_name);
    }, [data]);


    // 品質
    const [selectQualityIndicatorsData, setSelectQualityIndicatorsData] = useState<ProcessIoDataQualityValues[] | undefined>(); // 選択された品質（更新用）
    const [qualityIndicatorsDataList, setQualityIndicatorsDataList] = useState<any[] | undefined>(); // テーブルでの表示用 
    // 品質の初期設定
    useEffect(() => {
        if (data?.process_io_data_quality_values
            && dataQualityIndicatorsData
            && !selectQualityIndicatorsData
            && !qualityIndicatorsDataList
        ) {
            const matchedData = data.process_io_data_quality_values.map((qualityValue) => {
                return dataQualityIndicatorsData.find((indicator) => indicator.id === qualityValue.indicator_id);
            }).filter(Boolean);
            setSelectQualityIndicatorsData(data?.process_io_data_quality_values);
            setQualityIndicatorsDataList(matchedData as DataQualityIndicators[]);
        }
    }, [data, dataQualityIndicatorsData, setQualityIndicatorsDataList]);

    const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        const value = event.target.value;
        switch (key) {
            case "name":
                setInputElementaryFlowName(value);
                break;
            case "amount":
                setInputAmount(value === '' ? null : Number(value));
                break;
            case "amount_formula":
                setInputAmountFormula(value);
                break;
            case "compatible_product_name":
                setInputCompatibleProductName(value);
                break;
        }
    }
    const handleInputChangeTextArea = async (event: React.ChangeEvent<HTMLTextAreaElement>, key: string) => {
        switch (key) {
            case "public_comment":
                setInputPublicComment(event.target.value);
                break;
            case "private_comment":
                setInputPrivateComment(event.target.value);
                break;
            case "amount_comment":
                setInputAmountComment(event.target.value);
                break;
            case "information_sources":
                setInputInformationSources(event.target.value);
                break;
        }
    }
    const handleInputChangeSelect = async (event: React.ChangeEvent<HTMLSelectElement>, key: string) => {
        switch (key) {
            case "unit":
                const selectedValue = event.target.value;
                setInputUnitId(Number(selectedValue));
                // unitListの中のunitsから選択されたユニットを探す
                for (const group of unitList ?? []) {
                    const selectedUnit = group.units.find(unit => unit.id === Number(selectedValue));
                    if (selectedUnit) {
                        setInputUnitName(selectedUnit.name);
                        setInputUnitCode(selectedUnit.code);
                        setInputUnitLocale(selectedUnit.locale);
                        setInputUnitPrivateComment(selectedUnit.private_comment);
                        setInputUnitPublicComment(selectedUnit.public_comment);
                        break;
                    }
                }
                
                // 単位換算の適用
                const newAmount = applyUnitConversion(
                    Number(inputAmount),
                    Number(inputUnitId),
                    Number(selectedValue),
                    unitConversionsData,
                );
                setInputAmount(Number(newAmount));

                break;
            case "compatibility_level":
                setInputCompatibilityLevel(Number(event.target.value));
                break;
        }
    }

    // 指定されたインデックスの値を更新する関数
    const updateInProductAtIndex = (index: number, newValue: ProcessIosType) => {
        if (outElementaryFlow) {
            // 現在の inProduct 配列をコピー
            const updatedInProduct = [...outElementaryFlow];

            // 指定されたインデックスの値を更新
            if (index >= 0 && index < updatedInProduct.length) {
                updatedInProduct[index] = newValue;
                setOutElementaryFlow(updatedInProduct);
            } else {
                console.error("Index out of bounds");
            }
        } else {
            console.error("inProduct is undefined");
        }
    };

    const handleClickOK = async () => {
        if (!validateCheck()) {
            return;
        }
        setChangeCheckFlow(true)
        updateInProductAtIndex(Id, param);
        // console.log("param", param);
        onClose();
    }

    // テーブル追加データ
    const param: ProcessIosType = {
        amount: Number(inputAmount) ?? 0,
        amount_comment: inputAmountComment,
        compatible_product_name: inputCompatibleProductName,
        direction: 'out',
        exchange: {
            public_comment: data?.exchange.public_comment ?? "",
            private_comment: data?.exchange.private_comment ?? "",
            cas_number: "",
            id: inputElementaryFlowId ?? data?.exchange.id ?? 0,
            locale: "",
            name: inputElementaryFlowName,
            synonyms_text: data?.exchange.synonyms_text ?? "",
            include_items_text: data?.exchange.include_items_text ?? "",
            related_items_text: data?.exchange.related_items_text ?? "",
            global_id: "",
            unit: {
                id: data?.exchange.unit.id ?? 0,
                locale: data?.exchange.unit.locale ?? "",
                name: data?.exchange.unit.name ?? "",
                private_comment: data?.exchange.unit.private_comment ?? "",
                public_comment: data?.exchange.unit.public_comment ?? "",
                code: data?.exchange.unit.code ?? "",
            },
            identifier_scheme_values: [],
            type: "elementary_flow",
            created_username: "",
            category_values: data?.exchange.category_values ?? [],
            category1_code: data?.exchange.category1_code ?? 0,
            category2_code: data?.exchange.category2_code ?? 0,
            category3_code: data?.exchange.category3_code ?? 0,
            category1_name: data?.exchange.category1_name ?? "",
            category2_name: data?.exchange.category2_name ?? "",
            category3_name: data?.exchange.category3_name ?? "",
            is_lcia_result_flow: null,
        },
        process_io_no: data?.process_io_no ?? null,
        formula: inputAmountFormula ?? "",
        is_calculated_amount: data?.is_calculated_amount ?? true,
        global_id: data?.global_id ?? "",
        id: data?.id || null,
        information_sources: inputInformationSources ?? "",
        locale: data?.locale ?? "",
        private_comment: inputPrivateComment,
        public_comment: inputPublicComment,
        unit: {
            id: Number(inputUnitId) ?? 0,
            locale: inputUnitLocale,
            name: inputUnitName,
            private_comment: inputUnitPrivateComment,
            public_comment: inputUnitPublicComment,
            code: inputUnitCode,
        },
        treatment: null,
        upper_output: {
            id: 0,
            amount: 0,
            unit: {
                locale: "",
                name: "",
                code: "",
                private_comment: "",
                public_comment: ","
            },
            process: {
                name: "",
                id: 0,
                is_database_pack_master: data?.upper_output?.process.is_database_pack_master ?? false,
                global_id: data?.upper_output?.process.global_id ?? "",
            }
        },
        process_io_data_quality_values: selectQualityIndicatorsData ?? [],
        compatibility_level: inputCompatibilityLevel
    }

    const onSelectElementaryFlow = async (
        elementary_flow: {
            id: number,
            name: string,
            unit: {
                id: number,
                name: string
            },
            category1_name: string,
            category2_name: string,
            category3_name: string,
        }
    ) => {
        setCategoryName(elementary_flow.category1_name + "/" + elementary_flow.category2_name + "/" + elementary_flow.category3_name);
        setInputElementaryFlowId(elementary_flow.id);
        setInputElementaryFlowName(elementary_flow.name);
        // setIsModalOpen(false);
        setInputUnitName(elementary_flow.unit.name);
        setInputUnitId(elementary_flow.unit.id);
    }


    // 今すぐ評価する
    const { getCalculateMathFormula, calculateMathFormulaData, calculateMathFormulaError, calculateMathFormulaLoading } = useCcalculateMathFormula();
    const hundleEvaluateNow = async () => {
        // 入出力追加
        try {
            await getCalculateMathFormula(inputAmountFormula ?? "", processData?.process_parameters ?? [])
        } catch (error: any) {
            console.log(error)
            return;
        }
    }

    useEffect(() =>{
        if(calculateMathFormulaData && !calculateMathFormulaError) {
            if(calculateMathFormulaData.result_value) {
                setInputAmount(calculateMathFormulaData.result_value);
                setAmountFormulaError(null);
            }
            if(calculateMathFormulaData.error_message) {
                setAmountFormulaError(calculateMathFormulaData.error_message)
            }
        }
    },[calculateMathFormulaData])
    
    return (
        <Inner>
            <Title>{t('出力基本フロー（直接排出物）の編集')}</Title>
            <SectionBody>
                <SectionColumn>
                    <SectionColumnWrap>
                        <SectionColumnWrapTitle>{t('資源・排出物')}<RequiredSpan>※</RequiredSpan></SectionColumnWrapTitle>
                        <SectionColumnContent>
                            <DisabledInput
                                type="text"
                                onChange={(event) => handleInputChange(event, 'name')}
                                value={inputElementaryFlowName ?? ''}
                                disabled
                            />
                            {!processData?.is_database_pack_master && 
                                <ElementaryFlowSearchResourcesAndWasteEditModal type={"out"} onSelectElementaryFlow={onSelectElementaryFlow} />
                            }
                            {elementaryFlowNameError && <ErrorMsg>{elementaryFlowNameError}</ErrorMsg>}
                        </SectionColumnContent>
                    </SectionColumnWrap>
                    <SectionColumnWrap>
                        <SectionColumnWrapTitle>{t('分類')}</SectionColumnWrapTitle>
                        <SectionColumnContent>
                            <DisabledInput
                                disabled
                                type="text"
                                value={categoryName ?? ""}
                            />
                        </SectionColumnContent>
                    </SectionColumnWrap>
                    <SectionColumnWrap>
                        <SectionColumnWrapTitle>{t('流量の数式')}</SectionColumnWrapTitle>
                        <SectionColumnContent>
                            {!processData?.is_database_pack_master &&
                                <InputPrimary
                                    type="text"
                                    value={inputAmountFormula ?? ""}
                                    onChange={(event) => handleInputChange(event, 'amount_formula')}
                                />
                            }
                            {processData?.is_database_pack_master &&
                                <DisabledInput
                                    type="text"
                                    value={inputAmountFormula ?? ""}
                                    disabled
                                    readOnly
                                />
                            }
                            <FlowParameterSettingListModal inputAmountFormula={inputAmountFormula} setInputAmountFormula={setInputAmountFormula} />
                        </SectionColumnContent>
                        {amountFormulaError && <><SectionColumnContent></SectionColumnContent><ErrorMsg>{amountFormulaError}</ErrorMsg></>}
                    </SectionColumnWrap>

                    <SectionColumnWrap>
                        <SectionColumnWrapTitle></SectionColumnWrapTitle>
                        <SectionColumnContent>
                            {t('*数式はプロセス保存時に評価され流量に反映されます')}
                            {userData?.is_admin && !processData?.is_database_pack_master &&
                                <SectionColumnContentLink onClick={() =>{ hundleEvaluateNow() }}>[SystemTest]今すぐ評価する</SectionColumnContentLink>
                            }
                        </SectionColumnContent>
                    </SectionColumnWrap>

                    <SectionColumnWrap>
                        <SectionColumnWrapTitle>{t('流量')}<RequiredSpan>※</RequiredSpan></SectionColumnWrapTitle>
                        <SectionColumnContentShort>
                            <InputPrimaryShort
                                type="number"
                                value={inputAmount !== null && inputAmount !== undefined ? inputAmount : ''}
                                min={"0"}
                                onChange={(event) => handleInputChange(event, 'amount')}
                                disabled={!!inputAmountFormula || processData?.is_database_pack_master}
                            />
                            <SectionColumnContentShort>
                                <SelectInner
                                    value={inputUnitId ?? ''}
                                    onChange={(event) => handleInputChangeSelect(event, 'unit')}
                                    disabled={processData?.is_database_pack_master}
                                >
                                    {unitList?.map((units, index) => (
                                        units.units.map((unit) => (
                                            <option key={unit.name} value={unit.id}>
                                                {unit.name}
                                            </option>
                                        ))
                                    ))}
                                </SelectInner>
                            </SectionColumnContentShort>
                            {amountError && <ErrorMsg>{amountError}</ErrorMsg>}
                        </SectionColumnContentShort>
                    </SectionColumnWrap>
                </SectionColumn>
            </SectionBody>

            <Title>{t('適合性')}</Title>
            <SectionBody>
                <SectionColumn>
                    <SectionColumnWrap>
                        <SectionColumnWrapTitle>{t('情報源のフロー名')}</SectionColumnWrapTitle>
                        <SectionColumnContent>
                            {!processData?.is_database_pack_master &&
                                <InputPrimary
                                    type="text"
                                    value={inputCompatibleProductName ?? ''}
                                    onChange={(event) => handleInputChange(event, 'compatible_product_name')}
                                />
                            }
                            {processData?.is_database_pack_master &&
                                <DisabledInput
                                    type="text"
                                    value={inputCompatibleProductName ?? ''}
                                    disabled
                                    readOnly
                                />
                            }
                        </SectionColumnContent>
                    </SectionColumnWrap>
                    <SectionColumnWrap>
                        <SectionColumnWrapTitle>{t('適合度')}</SectionColumnWrapTitle>
                        <SectionColumnContent>
                            <SelectPrimary>
                                <SelectInner
                                    onChange={(event) => handleInputChangeSelect(event, 'compatibility_level')}
                                    value={inputCompatibilityLevel ?? "1"}
                                    disabled={processData?.is_database_pack_master}
                                >
                                    <option value="1">{t('よい')}</option>
                                    <option value="2">{t('普通')}</option>
                                    <option value="3">{t('悪い')}</option>
                                </SelectInner>
                            </SelectPrimary>
                        </SectionColumnContent>
                    </SectionColumnWrap>
                </SectionColumn>
            </SectionBody>

            <Title>{t('品質')}</Title>
            <SectionBody>
                <SectionColumn>
                    <ProcessIoDataQualityValueTable
                        selectQualityIndicatorsData={selectQualityIndicatorsData}
                        setSelectQualityIndicatorsData={setSelectQualityIndicatorsData}
                        qualityIndicatorsDataList={qualityIndicatorsDataList}
                        setQualityIndicatorsDataList={setQualityIndicatorsDataList}
                    />
                </SectionColumn>
            </SectionBody>

            <Title>{t('コメント')}</Title>
            <SectionBody>
                <SectionColumn>
                    <SectionColumnWrap>
                        <SectionColumnWrapTitle>{t('プライベートコメント')}</SectionColumnWrapTitle>
                        <SectionColumnContent>

                            {!processData?.is_database_pack_master &&
                                <TextArea
                                    name=""
                                    id=""
                                    cols={50}
                                    rows={2}
                                    onChange={(event) => handleInputChangeTextArea(event, 'private_comment')}
                                    value={inputPrivateComment ?? ''}
                                />
                            }
                            {processData?.is_database_pack_master &&
                                <DisabledTextArea
                                    cols={50}
                                    rows={2}
                                    value={inputPrivateComment ?? ''}
                                    disabled
                                    readOnly
                                />
                            }
                        </SectionColumnContent>

                    </SectionColumnWrap>
                    <SectionColumnWrap>
                        <SectionColumnWrapTitle>{t('公開コメント')}</SectionColumnWrapTitle>
                        <SectionColumnContent>
                            {!processData?.is_database_pack_master &&
                                <TextArea
                                    name=""
                                    id=""
                                    cols={50}
                                    rows={2}
                                    onChange={(event) => handleInputChangeTextArea(event, 'public_comment')}
                                    value={inputPublicComment ?? ''}
                                />
                            }
                            {processData?.is_database_pack_master &&
                                <DisabledTextArea
                                    cols={50}
                                    rows={2}
                                    value={inputPublicComment ?? ''}
                                    disabled
                                    readOnly
                                />
                            }
                        </SectionColumnContent>
                    </SectionColumnWrap>
                    <SectionColumnWrap>
                        <SectionColumnWrapTitle>{t('情報源')}</SectionColumnWrapTitle>
                        <SectionColumnContent>
                            {!processData?.is_database_pack_master &&
                                <TextArea
                                    name=""
                                    id=""
                                    cols={50}
                                    rows={2}
                                    onChange={(event) => handleInputChangeTextArea(event, 'information_sources')}
                                    value={inputInformationSources ?? ''}
                                />
                            }
                            {processData?.is_database_pack_master &&
                                <DisabledTextArea
                                    cols={50}
                                    rows={2}
                                    value={inputInformationSources ?? ''}
                                    disabled
                                    readOnly
                                />
                            }
                        </SectionColumnContent>

                    </SectionColumnWrap>
                    <SectionColumnWrap>
                        <SectionColumnWrapTitle>{t('流量に関するコメント')}</SectionColumnWrapTitle>
                        <SectionColumnContent>
                            {!processData?.is_database_pack_master &&
                                <TextArea
                                    name=""
                                    id=""
                                    cols={50}
                                    rows={2}
                                    onChange={(event) => handleInputChangeTextArea(event, 'amount_comment')}
                                    value={inputAmountComment ?? ''}
                                />
                            }
                            {processData?.is_database_pack_master &&
                                <DisabledTextArea
                                    cols={50}
                                    rows={2}
                                    value={inputAmountComment ?? ''}
                                    disabled
                                    readOnly
                                />
                            }
                        </SectionColumnContent>

                    </SectionColumnWrap>
                </SectionColumn>
            </SectionBody>
            <ButtonCreateSectionTabel>
                <ModalButton onClick={handleClickOK} text={t('変更')} disabled={processData?.is_database_pack_master}/>
            </ButtonCreateSectionTabel>
        </Inner>
    )
}

export default OutputElementaryFlowEditModal;

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;    
    line-height: 1.25;    
`

const SectionBody = styled.div`
    margin-bottom: 60px;
`

const SectionBodyLast = styled.div`
`

const SectionColumn = styled.dl`
`

const SectionColumnContent = styled.dd`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;
`

const SectionColumnContentShort = styled.dd`
    width: min(100%, 340px);
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;
`


const SectionColumnWrap = styled.div`
    margin-bottom: 8px;
    display: grid;
    align-items: center;
    grid-template-columns: 27.0833333333% minmax(0, 1fr);
    gap: 6px 40px;      
`

const SectionColumnWrapTitle = styled.dt`
    margin-bottom: 0;   
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`

const InputPrimary = styled.input`
    width: 100%;

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const DisabledInput = styled.input`
    width: 100%;

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: var(--color-bg-primary);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const InputPrimaryShort = styled.input<{ disabled?: boolean }>`
    width: 50%;

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
   
    background-color: ${(props) => (props.disabled ? 'var(--color-bg-primary)' : '#fff')};
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    &:disabled {
        pointer-events: none;
    }
`


const SelectPrimary = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointer;
`

const SelectPrimaryShort = styled.label`
    width: 50%;
    position: relative;
    display: block;
    cursor: pointer;
`

const SelectInner = styled.select`
    color: rgb(85, 85, 85);
    position: relative;
    width: 100%;
    padding: 10px 32px 10px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const TextArea = styled.textarea`
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    width: 100%;
`

const DisabledTextArea = styled.textarea`
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: var(--color-bg-primary);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    width: 100%;
`

const ButtonCreateSectionTabel = styled.div`
    margin-top: 40px;
    text-align: right;

    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;    
`

const ErrorMsg = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: var(--color-error-primary);
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
`;

const SectionColumnContentLink = styled.a`
    text-decoration: underline;
`